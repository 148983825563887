<template>
  <section class="vh-100">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="text-center mb-3"></div>
          <div class="card bg-dark text-white" style="border-radius: 1rem;">
            <div class="card-body p-5 text-center">
              <img src="https://meetingfiles.com/assets/images/sync-logo-white.png" width="150"/>
              <div class="mb-md-5 mt-md-4 pb-5">

                <p v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</p>

                <transition>
                  <div id="loginFormContainer" v-if="!loggedIn">
                    <form id="loginForm" @submit.prevent="submitLogin" :disabled="submitting">
                      <fieldset :disabled="loading">
                        <div class="mb-3">
                          <label>User Name</label>
                          <input type="text" class="form-control" v-model="username"/>
                        </div>
                        <div class="mb-3">
                          <label>Password</label>
                          <input type="password" class="form-control" v-model="password"/>
                        </div>
                        <div class="mt-3 d-grid gap-2" v-if="!submitting">
                          <input class="btn btn-outline-light btn-lg px-5" type="submit" value="Submit"/>
                        </div>
                        <div class="mt-3 d-grid gap-2" v-if="submitting">
                          <LoaderComponent/>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </transition>

                <transition>
                  <div id="stationContainer" v-if="showEventForm">
                    <form id="stationForm" @submit.prevent="submitStation">
                      <div class="mb-3">
                        <label>Select this Sync Stations Name:</label>
                        <select name="sync_stationsID" class="form-select">
                          <option value="">Select Sync Station Name</option>
                        </select>
                      </div>
                      <p class="text-center">
                        <em>If there are no sync station names, please log out and contact your admin to create one in the admin panel.</em>
                      </p>
                    </form>
                  </div>
                </transition>
              </div>

              <div>
                <p class="mb-0">Download the Desktop App <a href="https://sync.meetingfiles.com/desktop">Here</a></p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { useUserStore } from "@/store/modules/UseUserStore";

export default {
  name: "HomeComponent",
  components: {
    LoaderComponent
  },
  setup() {
    // Call the function to get the store instance
    const userStore = useUserStore();
    const router = useRouter();

    const username = ref("");
    const password = ref("");
    const errorMessage = ref(false);
    const loggedIn = ref(false);
    const showEventForm = ref(false);
    const submitting = ref(false);
    const loading = ref(false);

    const submitLogin = async () => {
      submitting.value = true;
      try {
        await userStore.authenticateUser({
          username: username.value,
          password: password.value
        });
        await router.push("/app/");
      } catch (error) {
        console.error("Login error:", error);
        errorMessage.value = userStore.authenticationErrorMessage;
      }
      submitting.value = false;
    };

    const submitStation = async () => {
      try {
        await userStore.authenticateUser({
          username: username.value,
          password: password.value
        });
        await router.push("/app/");
      } catch (error) {
        console.error("Station submission error:", error);
        errorMessage.value = userStore.authenticationErrorMessage;
      }
    };

    onMounted(async () => {
      try {
        await userStore.isAuthenticated();
        await router.push("/app/");
      } catch (error) {
        console.log("Not authenticated:", error);
      }
    });

    return {
      username,
      password,
      errorMessage,
      loggedIn,
      showEventForm,
      submitting,
      loading,
      submitLogin,
      submitStation
    };
  }
};
</script>

<style scoped lang="scss">
.side-link-container {
  background-color: #c0c0c0;
}

.login-card {
  margin: auto;
}
</style>
