<template>
  <div id="organizationsView">
    <div class="card mb-3">
      <div class="card-header">
        <h4>Create Organization</h4>
      </div>
      <div class="card-body">
        <form @submit.prevent="createNewOrganization">
          <div class="mb-3">
            <label class="form-label">Organization Name</label>
            <input type="text" class="form-control" v-model="newOrganization.organization_name" />
          </div>
          <div class="mb-3">
            <label class="form-label">Organization Acronym</label>
            <input type="text" class="form-control" v-model="newOrganization.organization_acronym" />
          </div>
          <div class="mb-3">
            <input class="btn btn-success" type="submit" value="submit" />
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="organizationsArray.length">
          <table class="table">
            <thead>
            <tr><th>Organization Name</th><th>Acronym</th></tr>
            </thead>
            <tbody>
            <tr v-for="organization in organizationsArray" :key="organization.organizationsID">
              <td>{{ organization.organization_acronym }}</td>
              <td>{{ organization.organization_name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p class="alert alert-warning">
            No organization data to show.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import { useOrganizationStore } from "@/store/modules/Organizations"; // Import Pinia Store
import axios from "axios";

export default {
  name: "OrganizationsView",
  setup() {
    const organizationStore = useOrganizationStore(); // Use Pinia Organization Store
    const newOrganization = ref({ organization_name: "", organization_acronym: "" });

    const organizationsArray = computed(() => organizationStore.organizationsArray);

    const createNewOrganization = async () => {
      if (newOrganization.value.organization_acronym !== "") {
        try {
          const response = await axios.post("/Organization", newOrganization.value);
          if (response.data.status === 200) {
            organizationStore.addOrganizationToArray(response.data.record); // Pinia Action
            newOrganization.value = { organization_name: "", organization_acronym: "" };
          }
        } catch (error) {
          console.error("Error creating organization:", error);
        }
      }
    };

    onMounted(() => {
      organizationStore.setOrganizationsData(); // Use Pinia Action
    });

    return {
      newOrganization,
      organizationsArray,
      createNewOrganization
    };
  },
};
</script>


<style scoped>
</style>
