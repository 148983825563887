<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script setup>
import { useUserStore } from '@/store/modules/UseUserStore.js';
import { onBeforeMount } from 'vue';

const userStore = useUserStore();

onBeforeMount(async () => {
  const response = await userStore.isAuthenticated();
  if (response) {
    // userStore.checkIfSyncStation(); // Uncomment if needed
  }
});
</script>
<style lang="scss"></style>
