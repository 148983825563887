<template>
  <div>
    <div class="">
      <input type="text" class="form-control" placeholder="Search Presenters" v-model="searchName" @keyup="searchPresenters"/>
    </div>
    <div v-if="showSearchResults" id="presenterSearchResults">
      <table class="table">
        <tbody>
        <tr v-for="searchResult in searchResults" :key="searchResult.presentersID">
          <td>{{ searchResult.presenters_fname }}</td>
          <td>{{ searchResult.presenters_lname }}</td>
          <td><a :href="'mailto:' + searchResult.presenters_email">{{ searchResult.presenters_email }}</a></td>
          <td class="btn-group">
            <button class="btn btn-sm btn-secondary" @click="editPresenter(searchResult)">
              <i class="bi bi-pencil-square"></i> edit presenter
            </button>
            <button class="btn btn-sm btn-secondary" @click="goToSchedule(searchResult)">
              <i class="bi bi-file-earmark-plus"></i> presenter schedule
            </button>
            <button class="btn btn-sm btn-secondary" @click="goToSync(searchResult)">
              <i class="bi bi-link-45deg"></i> goto sync
            </button>
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-secondary"
              @click="copyLink(searchResult)"
            >Copy Link</a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <p v-if="showNoSearchResultsAlert" class="alert alert-danger">
      <i class="bi bi-exclamation-octagon-fill"></i> No Results for this Name</p>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { http } from "@/api";

export default {
  name: "PresenterSearchForm",
  setup() {
    const searchName = ref("");
    const searchResults = ref([]);
    const showSearchResults = ref(false);
    const showNoSearchResultsAlert = ref(false);
    const router = useRouter();

    const searchPresenters = () => {
      let parameters = { searchClass: 'Presenter', searchMethod: 'searchByNames', term: searchName.value };

      if (searchName.value.length >= 3) {
        http.get("/public/search/", { params: parameters })
          .then(response => processSearchResults(response.data));
      } else {
        showSearchResults.value = false;
        searchResults.value = [];
      }
    };

    const processSearchResults = (data) => {
      if (data.record.length > 0) {
        showNoSearchResultsAlert.value = false;
        showSearchResults.value = true;
        searchResults.value = data.record;
      } else {
        showNoSearchResultsAlert.value = true;
      }
    };

    const editPresenter = (selectedPresenter) => {
      router.push({ name: 'PresenterView', params: { ID: selectedPresenter.presentersID } });
      showSearchResults.value = false;
    };

    const goToSchedule = (selectedPresenter) => {
      router.push({ name: 'PresenterScheduleView', params: { ID: selectedPresenter.presentersID } });
      showSearchResults.value = false;
    };

    const goToSync = (presenter) => {
      let url = encodeURI(`https://sync.meetingfiles.com/my-sync/auth.php?anyscreen=true&uuid=${presenter.uuid}`);
      window.open(url, "_blank");
      showSearchResults.value = false;
    };

    const copyLink = (searchResult) => {
      const link = `https://sync.meetingfiles.com/my-sync/?uuid=${searchResult.uuid}&pin=${searchResult.presenters_pin}`;
      navigator.clipboard.writeText(link).catch(err => console.error('Failed to copy link:', err));
    };

    return {
      searchName,
      searchResults,
      showSearchResults,
      showNoSearchResultsAlert,
      searchPresenters,
      editPresenter,
      goToSchedule,
      goToSync,
      copyLink
    };
  }
};
</script>

<style scoped>
#presenterSearchResults {
  position: absolute;
  z-index: 5000;
  background-color: #262A32;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 3px 3px 12px #c0c0c0;
  min-width: 940px;
}
.table td {
  border-bottom: 1px solid #fce587;
}
</style>
