<template>
  <div class="center">
    <div class="wave" v-for="n in 10" :key="n" :style="{ animationDelay: `${(n - 1) * 0.1}s` }"></div>
  </div>
</template>

<script>
export default {
  name: "LoaderComponent"
};
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
</style>
