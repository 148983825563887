<template>
  <div id="eventsView">
    <div class="row mb-5">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div v-if="myCurrentEventsArray.length">
              <table class="table">
                <thead>
                <tr>
                  <th>Event Name</th>
                  <th>Event Dates</th>
                  <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="event in myCurrentEventsArray" :key="event.eventsID">
                  <td>{{ event.event_title }}</td>
                  <td>{{ formatDate(event.event_start_date) }} - {{ formatDate(event.event_end_date) }}</td>
                  <td>
                    <router-link :to="{ name: 'EventDetailView', params: { ID: event.eventsID } }"
                                 class="btn btn-secondary me-3"><i class="bi bi-pencil-square"></i> view/edit</router-link>
                    <router-link :to="{ name: 'ScheduleUploadView', params: { ID: event.eventsID } }"
                                 class="btn btn-secondary me-3"><i class="bi bi-upload"></i> schedule upload</router-link>
                    <a :href="`https://sync.meetingfiles.com/api/public/plinks/?eid=${event.eventsID}`"
                       class="btn btn-secondary"><i class="bi bi-share-fill"></i> Presenter Links</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <p class="alert alert-warning">No event data to show.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Event -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4>Add a New Event</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="validateAndCreateEvent">
              <div class="mb-3">
                <label class="form-label">Company / Organization <em>(type to search database)</em></label>
                <input type="text" class="form-control" @keyup="searchOrg" v-model="searchName" />
                <div v-if="orgSearchArray.length" class="mt-2 list-group">
                  <a href="#" class="list-group-item list-group-item-action" v-for="org in orgSearchArray"
                     :key="org.organizationsID" @click.stop.prevent="selectOrg(org)">
                    ({{ org.organization_acronym }}) {{ org.organization_name }}
                  </a>
                </div>
                <div v-if="selectedOrg" class="mt-2 alert alert-info">
                  Selected: {{ selectedOrg.organization_name }}
                </div>
                <div v-if="errors.organization" class="text-danger">{{ errors.organization }}</div>
              </div>

              <div class="mb-3">
                <label class="form-label">Event Title</label>
                <input type="text" class="form-control" v-model="newEvent.event_title" />
                <div v-if="errors.title" class="text-danger">{{ errors.title }}</div>
              </div>

              <div class="mb-3">
                <label class="form-label">Event Start Date</label>
                <input type="date" class="form-control" v-model="newEvent.event_start_date" />
                <div v-if="errors.startDate" class="text-danger">{{ errors.startDate }}</div>
              </div>

              <div class="mb-3">
                <label class="form-label">Event End Date</label>
                <input type="date" class="form-control" v-model="newEvent.event_end_date" />
                <div v-if="errors.endDate" class="text-danger">{{ errors.endDate }}</div>
              </div>

              <div class="mb-3">
                <label class="form-label">Event Location</label>
                <input type="text" class="form-control" v-model="newEvent.event_location" />
                <div v-if="errors.location" class="text-danger">{{ errors.location }}</div>
              </div>

              <div class="mb-3">
                <label class="form-label">Main Room Name</label>
                <input type="text" class="form-control" v-model="mainRoom" />
                <div v-if="errors.mainRoom" class="text-danger">{{ errors.mainRoom }}</div>
              </div>

              <button type="button" @click.prevent="addRoom" class="mb-2 btn btn-secondary">+ Add Room</button>

              <div v-if="additionalRooms.length">
                <div v-for="(room, index) in additionalRooms" :key="index" class="input-group mb-2">
                  <input type="text" class="form-control" v-model="room.roomName" placeholder="Room Name" />
                  <span class="input-group-text" @click="removeRoom(index)"><i class="bi bi-x-circle-fill"></i></span>
                </div>
              </div>

              <div class="mb-3">
                <button type="submit" class="btn btn-success" :disabled="isSubmitting">
                  <span v-if="isSubmitting">Creating...</span>
                  <span v-else>Create Event</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useEventStore } from "@/store/modules/Events"; // Pinia Event Store
import { useUserStore } from "@/store/modules/UseUserStore.js"; // Pinia User Store
import { http } from "@/api"; // Use the configured HTTP client
import dayjs from "dayjs";

export default {
  name: "EventsView",
  setup() {
    const eventStore = useEventStore();
    const userStore = useUserStore();
    const router = useRouter();

    // Form state
    const newEvent = ref({
      organizationsID: "",
      event_title: "",
      event_start_date: "",
      event_end_date: "",
      event_location: "",
    });

    // Organization search state
    const searchName = ref("");
    const orgSearchArray = ref([]);
    const selectedOrg = ref(null);

    // Room management state
    const mainRoom = ref("");
    const additionalRooms = ref([]);

    // Form submission state
    const isSubmitting = ref(false);
    const errors = ref({});

    // Computed properties
    const myCurrentEventsArray = computed(() => eventStore.currentEventsArray || []);

    // Methods
    const searchOrg = async () => {
      if (searchName.value.length >= 3) {
        try {
          const response = await http.get("/public/search/", {
            params: {
              searchClass: "Organization",
              searchMethod: "searchByNames",
              term: searchName.value
            },
          });
          orgSearchArray.value = response.data.record || [];
        } catch (error) {
          console.error("Error searching organizations:", error);
        }
      } else {
        orgSearchArray.value = [];
      }
    };

    const selectOrg = (org) => {
      newEvent.value.organizationsID = org.organizationsID;
      searchName.value = org.organization_name;
      selectedOrg.value = org;
      orgSearchArray.value = [];
    };

    const validateForm = () => {
      errors.value = {};
      let isValid = true;

      if (!newEvent.value.organizationsID) {
        errors.value.organization = "Please select an organization";
        isValid = false;
      }

      if (!newEvent.value.event_title || newEvent.value.event_title.trim() === "") {
        errors.value.title = "Event title is required";
        isValid = false;
      }

      if (!newEvent.value.event_start_date) {
        errors.value.startDate = "Start date is required";
        isValid = false;
      }

      if (!newEvent.value.event_end_date) {
        errors.value.endDate = "End date is required";
        isValid = false;
      } else if (newEvent.value.event_start_date && newEvent.value.event_end_date) {
        const startDate = new Date(newEvent.value.event_start_date);
        const endDate = new Date(newEvent.value.event_end_date);
        if (endDate < startDate) {
          errors.value.endDate = "End date must be after start date";
          isValid = false;
        }
      }

      if (!newEvent.value.event_location || newEvent.value.event_location.trim() === "") {
        errors.value.location = "Event location is required";
        isValid = false;
      }

      if (!mainRoom.value || mainRoom.value.trim() === "") {
        errors.value.mainRoom = "Main room name is required";
        isValid = false;
      }

      return isValid;
    };

    const validateAndCreateEvent = () => {
      if (validateForm()) {
        createNewEvent();
      }
    };

    const createNewEvent = async () => {
      isSubmitting.value = true;

      try {
        // Ensure we have a valid group ID
        const groupID = userStore.getMyGroupID;
        if (!groupID) {
          console.error("No valid group ID available");
          errors.value.general = "User group information not available";
          isSubmitting.value = false;
          return;
        }

        newEvent.value.groupsID = groupID;

        const response = await http.post("/Event", newEvent.value);

        if (response.data && response.data.status === 200 && response.data.record && response.data.record.eventsID) {
          const eventID = response.data.record.eventsID;

          // Add to store
          eventStore.addEventToArray(response.data.record);

          // Create rooms
          await processRooms(eventID);

          // Reset form
          resetForm();

          // Navigate to event detail
          router.push({name: "EventDetailView", params: {ID: eventID}});
        } else {
          throw new Error("Invalid response from server");
        }
      } catch (error) {
        console.error("Error creating event:", error);
        errors.value.general = "Error creating event. Please try again.";
      } finally {
        isSubmitting.value = false;
      }
    };

    const processRooms = async (eventID) => {
      try {
        // Create main room first
        if (mainRoom.value.trim()) {
          await http.post("/EventRoom", {
            eventsID: eventID,
            room_name: mainRoom.value.trim()
          });
        }

        // Create additional rooms
        for (const room of additionalRooms.value) {
          if (room.roomName && room.roomName.trim()) {
            await http.post("/EventRoom", {
              eventsID: eventID,
              room_name: room.roomName.trim()
            });
          }
        }
      } catch (error) {
        console.error("Error creating rooms:", error);
        // Continue despite errors - the event is created and can be edited later
      }
    };

    const resetForm = () => {
      newEvent.value = {
        organizationsID: "",
        event_title: "",
        event_start_date: "",
        event_end_date: "",
        event_location: "",
      };
      searchName.value = "";
      selectedOrg.value = null;
      mainRoom.value = "";
      additionalRooms.value = [];
      errors.value = {};
    };

    const addRoom = () => {
      additionalRooms.value.push({roomName: ""});
    };

    const removeRoom = (index) => {
      additionalRooms.value.splice(index, 1);
    };

    const formatDate = (date) => {
      if (!date) return "N/A";
      return dayjs(date).format("MMMM D, YYYY");
    };

    onMounted(async () => {
      try {
        await eventStore.setEventsData();
      } catch (error) {
        console.error("Error loading events:", error);
      }
    });

    return {
      newEvent,
      searchName,
      orgSearchArray,
      selectedOrg,
      myCurrentEventsArray,
      mainRoom,
      additionalRooms,
      isSubmitting,
      errors,
      searchOrg,
      selectOrg,
      validateAndCreateEvent,
      addRoom,
      removeRoom,
      formatDate,
    };
  },
};
</script>

<style scoped>
.text-danger {
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
</style>
