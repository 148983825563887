function getTitle(instance) {
  const { title } = instance.$options || {};
  return typeof title === 'function' ? title.call(instance) : title;
}

export default {
  mounted() {
    const title = getTitle(this);
    if (title) {
      document.title = title;
    }
  }
};
