import { defineStore } from "pinia";
import userService from "@/service/UserService";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    userData: {},
    authenticated: false,
    authenticationErrorMessage: "",
    sideBarOpen: true
  }),

  getters: {
    isUserAuthenticated: (state) => state.authenticated,
    getUserData: (state) => state.userData,
    authenticationErrorMessage: (state) => state.authenticationErrorMessage,
    userLevel: (state) => state.userData?.user_access_level ?? null,
    getMyGroupID: (state) => state.userData?.groupsID ?? false
  },

  actions: {
    // Toggle sidebar action
    toggleSidebar() {
      this.sideBarOpen = !this.sideBarOpen;
    },

    // Set sidebar state explicitly
    setSidebarState(isOpen) {
      this.sideBarOpen = isOpen;
    },

    async isAuthenticated() {
      try {
        const response = await userService.checkAuthentication();
        if (response.data.status === 200) {
          console.log(response);
          this.userData = response.data.record;
          this.authenticated = true;
        } else {
          this.authenticated = false;
          throw new Error("User is not authenticated");
        }
      } catch (error) {
        this.authenticated = false;
        console.error("Authentication check failed:", error);
        throw error;
      }
    },

    async authenticateUser(payload) {
      try {
        const response = await userService.authenticate(payload.username, payload.password);
        if (response.data.status === 200) {
          this.authenticated = true;
          this.userData = response.data.userData;
        } else {
          this.authenticationErrorMessage = "Invalid credentials";
          throw new Error("Invalid credentials");
        }
      } catch (error) {
        this.authenticationErrorMessage = "There was an error. Please try again.";
        console.error("Authentication failed:", error);
        throw error;
      }
    },

    async updateUserData() {
      try {
        const response = await userService.update(this.userData.userID, this.userData);
        if (response.data.status === 200) {
          this.userData = response.data.record;
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        console.error("Error updating user data:", error);
        throw error;
      }
    },

    async logOut() {
      try {
        const response = await userService.logOut();
        if (response.data.status === 200) {
          this.authenticated = false;
          this.userData = {};
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        console.error("Logout failed:", error);
        throw error;
      }
    }
  }
});
