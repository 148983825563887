import { http } from "@/api";

class EventService {

    getAll() {
        return http.get('/Event/');
    }
}

export default new EventService();
