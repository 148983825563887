<template>
  <div id="usersAdminView">
    <h5 class="alert alert-info">Users Admin</h5>

    <!-- Loading State -->
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <!-- Main Content -->
    <div v-else class="row">
      <!-- Add User Form -->
      <div class="col-md-6">
        <div class="card mb-5">
          <div class="card-header">
            <h4>Add a User</h4>
          </div>
          <div class="card-body">
            <!-- Form Messages -->
            <div v-if="showMessage" class="alert" :class="isError ? 'alert-danger' : 'alert-success'" role="alert">
              {{ formMessage }}
            </div>

            <form @submit.prevent="validateAndSubmit">
              <div class="mb-3">
                <label for="firstName" class="form-label">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  class="form-control"
                  v-model="newUser.user_fname"
                  :class="{'is-invalid': formErrors.user_fname}"
                  :disabled="isSubmitting"
                  required
                />
                <div v-if="formErrors.user_fname" class="invalid-feedback">
                  {{ formErrors.user_fname }}
                </div>
              </div>

              <div class="mb-3">
                <label for="lastName" class="form-label">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  class="form-control"
                  v-model="newUser.user_lname"
                  :class="{'is-invalid': formErrors.user_lname}"
                  :disabled="isSubmitting"
                  required
                />
                <div v-if="formErrors.user_lname" class="invalid-feedback">
                  {{ formErrors.user_lname }}
                </div>
              </div>

              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input
                  type="email"
                  id="email"
                  class="form-control"
                  v-model="newUser.user_email"
                  :class="{'is-invalid': formErrors.user_email}"
                  :disabled="isSubmitting"
                  required
                />
                <div v-if="formErrors.user_email" class="invalid-feedback">
                  {{ formErrors.user_email }}
                </div>
              </div>

              <div class="mb-3">
                <label for="username" class="form-label">Username</label>
                <input
                  type="text"
                  id="username"
                  class="form-control"
                  v-model="newUser.user_username"
                  :class="{'is-invalid': formErrors.user_username}"
                  :disabled="isSubmitting"
                  required
                />
                <div v-if="formErrors.user_username" class="invalid-feedback">
                  {{ formErrors.user_username }}
                </div>
              </div>

              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input
                  type="password"
                  id="password"
                  class="form-control"
                  v-model="newUser.user_ace"
                  :class="{'is-invalid': formErrors.user_ace}"
                  :disabled="isSubmitting"
                  required
                />
                <div v-if="formErrors.user_ace" class="invalid-feedback">
                  {{ formErrors.user_ace }}
                </div>
              </div>

              <div class="mb-3">
                <label for="accessLevel" class="form-label">Access Level</label>
                <select
                  id="accessLevel"
                  class="form-select"
                  v-model="newUser.user_access_level"
                  :class="{'is-invalid': formErrors.user_access_level}"
                  :disabled="isSubmitting"
                  required
                >
                  <option value="2">Group Admin</option>
                  <option value="3">Group Viewer</option>
                </select>
                <div v-if="formErrors.user_access_level" class="invalid-feedback">
                  {{ formErrors.user_access_level }}
                </div>
              </div>

              <div class="mb-3">
                <label for="group" class="form-label">Group</label>
                <select
                  id="group"
                  class="form-select"
                  v-model="newUser.groupsID"
                  :class="{'is-invalid': formErrors.groupsID}"
                  :disabled="isSubmitting"
                  required
                >
                  <option value="">Select a Group to Assign User To</option>
                  <option v-for="group in groupsArray" :key="group.groupsID" :value="group.groupsID">
                    {{ group.group_name }}
                  </option>
                </select>
                <div v-if="formErrors.groupsID" class="invalid-feedback">
                  {{ formErrors.groupsID }}
                </div>
              </div>

              <div class="mb-3">
                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="isSubmitting"
                >
                  <span v-if="isSubmitting">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Creating...
                  </span>
                  <span v-else>Create User</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Users List -->
      <div class="col-md-6">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h4>Users</h4>
            <button
              class="btn btn-sm btn-outline-primary"
              @click="refreshUserData"
              :disabled="isRefreshing"
            >
              <i class="bi bi-arrow-clockwise me-1"></i>
              <span v-if="isRefreshing">Refreshing...</span>
              <span v-else>Refresh</span>
            </button>
          </div>
          <div class="card-body">
            <div v-if="isRefreshing" class="text-center my-3">
              <div class="spinner-border spinner-border-sm text-primary" role="status">
                <span class="visually-hidden">Refreshing...</span>
              </div>
              <span class="ms-2">Refreshing user data...</span>
            </div>

            <div v-else-if="groupsArray.length">
              <div v-for="group in groupsArray" :key="group.groupsID" class="mb-4">
                <h5 class="bg-light p-2 rounded">{{ group.group_name }}</h5>

                <div v-if="group.users && group.users.length" class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Email</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="user in group.users" :key="user.usersID">
                      <td>{{ user.user_fname }} {{ user.user_lname }}</td>
                      <td>{{ user.user_username }}</td>
                      <td>{{ user.user_email }}</td>
                      <td>
                        <a
                          :href="`https://sync.meetingfiles.com/cp/?uuid=${user.uuid}`"
                          class="btn btn-sm btn-outline-secondary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i class="bi bi-key-fill me-1"></i>
                          Set Password
                        </a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else class="alert alert-warning">
                  No users in this group.
                </div>
              </div>
            </div>
            <div v-else>
              <p class="alert alert-warning">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>
                No groups or user data to show.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useGroupStore } from '@/store/modules/Groups';
import { http } from '@/api'; // Use the configured HTTP client

export default {
  name: "UsersAdminView",
  setup() {
    // Store
    const groupStore = useGroupStore();

    // State
    const newUser = ref({
      user_fname: '',
      user_lname: '',
      user_email: '',
      user_username: '',
      user_ace: '',
      user_access_level: '3', // String to match select value
      groupsID: ''
    });

    const loading = ref(true);
    const isSubmitting = ref(false);
    const isRefreshing = ref(false);
    const showMessage = ref(false);
    const isError = ref(false);
    const formMessage = ref('');
    const formErrors = ref({});

    // Computed properties
    const groupsArray = computed(() => groupStore.groupsArray || []);

    // Form validation
    const validateForm = () => {
      formErrors.value = {};
      let isValid = true;

      // Check each required field
      if (!newUser.value.user_fname.trim()) {
        formErrors.value.user_fname = "First name is required";
        isValid = false;
      }

      if (!newUser.value.user_lname.trim()) {
        formErrors.value.user_lname = "Last name is required";
        isValid = false;
      }

      if (!newUser.value.user_email.trim()) {
        formErrors.value.user_email = "Email is required";
        isValid = false;
      } else if (!isValidEmail(newUser.value.user_email)) {
        formErrors.value.user_email = "Please enter a valid email address";
        isValid = false;
      }

      if (!newUser.value.user_username.trim()) {
        formErrors.value.user_username = "Username is required";
        isValid = false;
      }

      if (!newUser.value.user_ace) {
        formErrors.value.user_ace = "Password is required";
        isValid = false;
      } else if (newUser.value.user_ace.length < 6) {
        formErrors.value.user_ace = "Password must be at least 6 characters";
        isValid = false;
      }

      if (!newUser.value.user_access_level) {
        formErrors.value.user_access_level = "Access level is required";
        isValid = false;
      }

      if (!newUser.value.groupsID) {
        formErrors.value.groupsID = "Please select a group";
        isValid = false;
      }

      return isValid;
    };

    const isValidEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    // Submit handler
    const validateAndSubmit = () => {
      if (validateForm()) {
        submitNewUser();
      }
    };

    // Create new user
    const submitNewUser = async () => {
      isSubmitting.value = true;
      showMessage.value = false;

      try {
        const response = await http.post('/User', newUser.value);

        if (response.data && response.data.status === 200) {
          // Add user to group in store
          groupStore.addUserToGroup(response.data.record);

          // Show success message
          formMessage.value = "User created successfully";
          isError.value = false;
          showMessage.value = true;

          // Clear the form
          clearForm();

          // Hide message after 3 seconds
          setTimeout(() => {
            showMessage.value = false;
          }, 3000);
        } else {
          throw new Error(response.data?.message || "Failed to create user");
        }
      } catch (error) {
        console.error("Error creating user:", error);

        // Show error message
        formMessage.value = error.response?.data?.message || "Failed to create user. Please try again.";
        isError.value = true;
        showMessage.value = true;
      } finally {
        isSubmitting.value = false;
      }
    };

    // Clear form fields
    const clearForm = () => {
      newUser.value = {
        user_fname: '',
        user_lname: '',
        user_email: '',
        user_username: '',
        user_ace: '',
        user_access_level: '3',
        groupsID: ''
      };
      formErrors.value = {};
    };

    // Refresh user data
    const refreshUserData = async () => {
      isRefreshing.value = true;

      try {
        await groupStore.setGroupsData();
      } catch (error) {
        console.error("Error refreshing user data:", error);
      } finally {
        isRefreshing.value = false;
      }
    };

    // Initialize component
    onMounted(async () => {
      loading.value = true;

      try {
        // Fetch users and groups
        await http.get('/User');

        if (!groupStore.groupsArray.length) {
          await groupStore.setGroupsData();
        }
      } catch (error) {
        console.error("Error loading users and groups:", error);
      } finally {
        loading.value = false;
      }
    });

    return {
      // State
      newUser,
      loading,
      isSubmitting,
      isRefreshing,
      showMessage,
      isError,
      formMessage,
      formErrors,

      // Computed
      groupsArray,

      // Methods
      validateAndSubmit,
      clearForm,
      refreshUserData
    };
  }
};
</script>

<style scoped>
.invalid-feedback {
  display: block;
}

.table-responsive {
  overflow-x: auto;
}

.card-header {
  background-color: #f8f9fa;
}

/* Fixed height for user tables section with scrolling */
@media (min-height: 700px) {
  .card-body {
    max-height: 70vh;
    overflow-y: auto;
  }
}
</style>
