import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

/** CSS **/
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

/** Plugins **/
import api from "./api";

/** Mixins **/
import titleMixin from './mixins/titleMixin';

/** Extend Day.js **/
dayjs.extend(customParseFormat);

const syncApp = createApp(App);
const pinia = createPinia();

syncApp.use(router);
syncApp.use(pinia);
syncApp.use(api); // Use the Axios plugin
syncApp.mixin(titleMixin);

// Provide global utilities
syncApp.provide('dayjs', dayjs);

syncApp.mount('#app');
