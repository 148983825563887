import { defineStore } from "pinia";
import organizationService from "@/service/OrganizationService";

export const useOrganizationStore = defineStore("organizationStore", {
  state: () => ({
    organizationsArray: null
  }),

  getters: {
    organizationsArray: (state) => state.organizationsArray
  },

  actions: {
    async setOrganizationsData() {
      try {
        const response = await organizationService.getAll();
        if (response.data.status === 200) {
          this.organizationsArray = response.data.record;
        }
      } catch (error) {
        console.error(error);
      }
    },

    addOrganizationToArray(organization) {
      if (this.organizationsArray) {
        this.organizationsArray.push(organization);
      }
    }
  }
});
