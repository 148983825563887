<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div v-if="workingEvent">
          <div class="card">
            <div class="card-header">
              <h4>Upload a Schedule for Event: {{ workingEvent.event_title }}</h4>
            </div>
            <div class="card-body">
              <form id="scheduleUploadForm" @submit.prevent.stop="processSchedule">
                <div class="mb-3">
                  <label class="form-label">Select Your CSV File</label>
                  <input type="file" name="csv-schedule" class="form-control" @change="fileChange"/>
                </div>
                <div>
                  <input type="submit" name="submit" value="Submit" class="btn btn-success"/>
                </div>
              </form>

              <div class="mt-3">
                <p v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</p>
                <div v-if="showErrorArray" class="mt-2">
                  <p v-for="(error, index) in errorArray" :key="index" class="alert alert-danger">{{ error }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="alert alert-warning">No event data found.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useEventStore } from "@/store/modules/Events"; // Import your Pinia store
import { useRoute } from "vue-router";
import { http } from "@/api"; // Replace with your actual Axios import

export default {
  name: "EventDetailView",
  setup() {
    const eventStore = useEventStore();
    const route = useRoute();

    const scheduleFile = ref(null);
    const eventsID = ref(route.params.ID);
    const showErrorMessage = ref(false);
    const showErrorArray = ref(true);
    const errorMessage = ref(null);
    const errorArray = ref(null);

    const workingEvent = computed(() => eventStore.workingEvent);

    onMounted(() => {
      eventStore.setWorkingEvent(eventsID.value);
    });

    watch(workingEvent, (newVal) => {
      if (newVal && newVal.rooms) {
        newVal.rooms.forEach(room => room.isEditing = false);
      }
    }, {immediate: true});

    const fileChange = (event) => {
      scheduleFile.value = event.target.files[0];
    };

    const processSchedule = async () => {
      if (!scheduleFile.value) {
        errorMessage.value = "Please select a file.";
        showErrorMessage.value = true;
        return;
      }

      let formData = new FormData();
      formData.append("altMethod", "PUT");
      formData.append("upload-file", scheduleFile.value);
      formData.append("eventsID", eventsID.value);

      try {
        const response = await http.post('/UploadSchedule', formData);
        if (response.data.status === 200) {
          console.log("Success");
        } else {
          console.log(response);
          if (response.data.record) {
            errorMessage.value = response.data.record.message || "There was an error but no message from the server.";
            errorArray.value = response.data.record.errors || [];
            showErrorMessage.value = true;
            showErrorArray.value = errorArray.value.length > 0;
          } else {
            errorMessage.value = "There was an error but no message from the server.";
            showErrorMessage.value = true;
          }
        }
      } catch (error) {
        errorMessage.value = "An error occurred while uploading the file.";
        showErrorMessage.value = true;
        console.error(error);
      }
    };

    return {
      scheduleFile,
      eventsID,
      showErrorMessage,
      showErrorArray,
      errorMessage,
      errorArray,
      workingEvent,
      fileChange,
      processSchedule,
    };
  }
};
</script>

<style scoped>
</style>
