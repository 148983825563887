<template>
  <div>
    <HeaderComponent/>
    <div class="container-fluid">
      <div class="row">
        <SidebarComponent/>
        <main class="col-md-10 ms-sm-auto col-lg-11 px-md-4 mt-3">
          <router-view/>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted} from "vue";
import {useUserStore} from "@/store/modules/UseUserStore";
import SidebarComponent from "@/components/SidebarComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "AppFrame",
  components: {
    SidebarComponent,
    HeaderComponent,
  },
  setup() {
    const userStore = useUserStore();

    onMounted(async () => {
      try {
        await userStore.isAuthenticated();
      } catch (error) {
        console.error("Authentication check failed:", error);
        // Handle authentication error (maybe redirect to login)
      }
    });

    // Use computed to bind sideBarOpen from the store to the component
    const sideBarOpen = computed(() => userStore.sideBarOpen);

    return {
      sideBarOpen
    };
  }
};
</script>

<style scoped lang="scss">
.side-bar-container {
  background-color: #c0c0c0;
}
</style>
