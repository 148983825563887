import { defineStore } from "pinia";
import eventService from "@/service/EventService";

export const useEventStore = defineStore("eventStore", {
  state: () => ({
    currentEventsArray: [],
    workingEvent: {
      groupsID: '', organizationsID: '', event_title: '', event_start_date: '',
      event_end_date: '', event_location: '', show_disclosure: 0, event_disclosure_text: '',
      show_record_auth: 0, record_auth_text: '', show_consent_check: 0, consent_text: '',
      show_number_label: 0, number_label_text: ''
    },
    workingRoom: {}
  }),

  getters: {

  },

  actions: {
    async setEventsData() {
      try {
        const response = await eventService.getAll();
        if (response.data.status === 200) {
          this.currentEventsArray = response.data.record;
        }
      } catch (error) {
        console.error(error);
      }
    },

    addEventToArray(event) {
      this.currentEventsArray.push(event);
    },

    setWorkingEvent(event) {
      this.workingEvent = event;
    },

    updateEventArray(event) {
      const index = this.currentEventsArray.findIndex(e => e.eventsID === event.eventsID);
      if (index !== -1) {
        this.currentEventsArray[index] = event;
      }
    },

    updateEventRoom(eventRoom) {
      this.currentEventsArray.forEach(event => {
        const roomIndex = event.rooms?.findIndex(room => room.event_roomsID === eventRoom.event_roomsID);
        if (roomIndex !== -1) {
          event.rooms[roomIndex] = eventRoom;
        }
      });
    },

    addRoomToEvent({ eventsID, newRoom }) {
      const event = this.currentEventsArray.find(event => event.eventsID === eventsID);
      if (event) {
        event.rooms = event.rooms ? [...event.rooms, newRoom] : [newRoom];
      }
    },

    removeRoomFromEvent({ eventsID, event_roomsID }) {
      const event = this.currentEventsArray.find(event => event.eventsID === eventsID);
      if (event) {
        event.rooms = event.rooms.filter(room => room.event_roomsID !== event_roomsID);
      }
    },

    updateEventStation(syncStation) {
      this.currentEventsArray.forEach(event => {
        const stationIndex = event.syncStations?.findIndex(station => station.sync_stationsID === syncStation.sync_stationsID);
        if (stationIndex !== -1) {
          event.syncStations[stationIndex] = syncStation;
        }
      });
    },

    addStationToEvent({ eventsID, newStation }) {
      const event = this.currentEventsArray.find(event => event.eventsID === eventsID);
      if (event) {
        event.syncStations = event.syncStations ? [...event.syncStations, newStation] : [newStation];
      }
    },

    removeStationFromEvent({ eventsID, sync_stationsID }) {
      const event = this.currentEventsArray.find(event => event.eventsID === eventsID);
      if (event) {
        event.syncStations = event.syncStations.filter(station => station.sync_stationsID !== sync_stationsID);
      }
    },

    setWorkingRoom(room) {
      this.workingRoom = room;
    }
  }
});
