<template>
  <nav id="sidebarMenu" class="col-md-2 col-lg-1 d-md-block bg-dark sidebar collapse vh-100 border-end border-1 border-secondary">
    <div class="position-sticky pt-3">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link :to="{ name: 'DashboardHome' }" class="nav-link">Dashboard</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'PresenterView' }" class="nav-link">Presenters</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'EventsView' }" class="nav-link">Events</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'OrganizationsView' }" class="nav-link">Organizations</router-link>
        </li>
      </ul>
      <ul v-if="userIsAdmin">
        <li class="nav-item">
          <router-link :to="{ name: 'GroupsAdminView' }" class="nav-link">Groups</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'UsersAdminView' }" class="nav-link">Users</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { computed } from "vue";
import { useUserStore } from "@/store/modules/UseUserStore.js";
import { useRouter } from "vue-router";

export default {
  name: "SidebarComponent",
  setup() {
    const userStore = useUserStore();
    const router = useRouter();

    const userIsAdmin = computed(() => userStore.userLevel === 1);

    const exitApp = () => {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      userStore.authenticated = false;
      router.push("/");
    };

    return {
      userIsAdmin,
      exitApp
    };
  }
};
</script>

<style scoped lang="scss">

</style>
