import { http } from "@/api";

class OrganizationService {

    getAll() {
        return http.get('/Organization/');
    }
}

export default new OrganizationService();
