import { defineStore } from "pinia";

export const useSyncMachineStore = defineStore("syncMachineStore", {
  state: () => ({
    syncMachine: {}
  }),

  getters: {
    getSyncMachine: (state) => state.syncMachine
  },

  actions: {
    setSyncMachine(syncMachineData) {
      this.syncMachine = syncMachineData;
    }
  }
});
