<template>
  <div>
    <div v-if="error">
      <p class="alert alert-danger">{{ errorMessage }}</p>
    </div>
    <div v-else-if="loading">
      <p class="alert alert-info">Loading event details...</p>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-6">
          <div v-if="workingEvent">
            <div class="card">
              <div class="card-header">
                <h4>Edit Event: {{ workingEvent.event_title }}</h4>
              </div>
              <div class="card-body">
                <form @submit.prevent="updateEvent">
                  <div class="mb-3">
                    <label class="form-label">Company / Organization <em>(type to search database)</em></label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="organizationName"
                      @keyup="searchOrg"
                    />
                    <input type="hidden" class="form-control" v-model="workingEvent.organizationsID"/>
                    <div v-if="orgSearchArray.length > 0" class="mt-2 list-group">
                      <a
                        href="#"
                        class="list-group-item list-group-item-action"
                        v-for="org in orgSearchArray"
                        :key="org.organizationsID"
                        @click.stop.prevent="selectOrg(org)"
                      >
                        ({{ org.organization_acronym }}) {{ org.organization_name }}
                      </a>
                    </div>
                    <div v-if="formErrors.organization" class="text-danger mt-1">
                      {{ formErrors.organization }}
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Event Title</label>
                    <input type="text" class="form-control" v-model="workingEvent.event_title"/>
                    <div v-if="formErrors.title" class="text-danger mt-1">
                      {{ formErrors.title }}
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Event Start Date</label>
                    <input type="date" class="form-control" v-model="workingEvent.event_start_date"/>
                    <div v-if="formErrors.startDate" class="text-danger mt-1">
                      {{ formErrors.startDate }}
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Event End Date</label>
                    <input type="date" class="form-control" v-model="workingEvent.event_end_date"/>
                    <div v-if="formErrors.endDate" class="text-danger mt-1">
                      {{ formErrors.endDate }}
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Event Location</label>
                    <input type="text" class="form-control" v-model="workingEvent.event_location"/>
                    <div v-if="formErrors.location" class="text-danger mt-1">
                      {{ formErrors.location }}
                    </div>
                  </div>
                  <div class="mb-3">
                    <button type="submit" class="btn btn-success" :disabled="isSubmitting">
                      <span v-if="isSubmitting">Updating...</span>
                      <span v-else>Update Event</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <!-- Rooms -->
          <div class="card mb-3">
            <div class="card-header">
              <h4>Rooms</h4>
            </div>
            <div class="card-body">
              <div>
                <div class="mb-2">
                  <label class="form-label">New Room Name</label>
                  <input type="text" class="form-control" v-model="newRoom" placeholder="Room Name">
                  <div v-if="formErrors.newRoom" class="text-danger mt-1">
                    {{ formErrors.newRoom }}
                  </div>
                </div>
                <button
                  type="button"
                  @click.prevent="addRoom"
                  class="mb-2 btn btn-secondary"
                  :disabled="isRoomSubmitting"
                >
                  <span v-if="isRoomSubmitting">Adding...</span>
                  <span v-else>+ Add Room</span>
                </button>
              </div>
              <hr>
              <div v-if="workingEvent.rooms && workingEvent.rooms.length > 0">
                <table class="table">
                  <tbody>
                  <tr v-for="(room, index) in workingEvent.rooms" :key="room.event_roomsID">
                    <td>
                      <input type="text" class="form-control" v-model="room.room_name" :disabled="!room.isEditing">
                    </td>
                    <td>
                      <router-link
                        :to="{ name: 'EventRoomSchedule', params: { ID: room.event_roomsID } }"
                        class="btn btn-secondary me-3"
                      >
                        <i class="bi bi-calendar-date"></i>
                      </router-link>
                      <button
                        type="button"
                        v-if="!room.isEditing"
                        class="btn btn-secondary me-3"
                        @click="editRoom(index)"
                      >
                        <i class="bi bi-pencil-square"></i>
                      </button>
                      <button
                        type="button"
                        v-if="room.isEditing"
                        class="btn btn-success me-3"
                        @click="saveRoom(index)"
                        :disabled="isRoomEditing"
                      >
                        <i class="bi bi-check-circle"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary me-2"
                        @click="removeRoom(index)"
                        :disabled="isRoomEditing"
                      >
                        <i class="bi bi-x-circle-fill"></i>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <p class="alert alert-warning">There are no rooms for this event</p>
              </div>
            </div>
          </div>

          <!-- Sync Stations -->
          <div class="card">
            <div class="card-header">
              <h4>Sync Stations</h4>
            </div>
            <div class="card-body">
              <div>
                <div class="mb-2">
                  <label class="form-label">New Sync Station Name</label>
                  <input type="text" class="form-control" v-model="newSyncStationName" placeholder="Sync Station Name">
                  <div v-if="formErrors.newStation" class="text-danger mt-1">
                    {{ formErrors.newStation }}
                  </div>
                </div>
                <button
                  type="button"
                  @click.prevent="addStation"
                  class="mb-2 btn btn-secondary"
                  :disabled="isStationSubmitting"
                >
                  <span v-if="isStationSubmitting">Adding...</span>
                  <span v-else>+ Add Sync Station</span>
                </button>
              </div>
              <hr>
              <div v-if="workingEvent.syncStations && workingEvent.syncStations.length > 0">
                <table class="table">
                  <tbody>
                  <tr v-for="(station, index) in workingEvent.syncStations" :key="station.sync_stationsID">
                    <td>
                      <input type="text" class="form-control" v-model="station.station_name" :disabled="!station.isEditing">
                    </td>
                    <td>
                      <button
                        type="button"
                        v-if="!station.isEditing"
                        class="btn btn-secondary me-3"
                        @click="editStation(index)"
                      >
                        <i class="bi bi-pencil-square"></i>
                      </button>
                      <button
                        type="button"
                        v-if="station.isEditing"
                        class="btn btn-success me-3"
                        @click="saveStation(index)"
                        :disabled="isStationEditing"
                      >
                        <i class="bi bi-check-circle"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary me-2"
                        @click="removeStation(index)"
                        :disabled="isStationEditing"
                      >
                        <i class="bi bi-x-circle-fill"></i>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <p class="alert alert-warning">There are no sync stations for this event</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useEventStore } from "@/store/modules/Events";
import { useRoute } from "vue-router";
import { http } from "@/api"; // Use configured HTTP client

export default {
  name: "EventDetailView",
  setup() {
    const eventStore = useEventStore();
    const route = useRoute();

    // State
    const loading = ref(true);
    const error = ref(false);
    const errorMessage = ref("");
    const newRoom = ref("");
    const newSyncStationName = ref("");
    const orgSearchArray = ref([]);
    const organizationName = ref("");
    const formErrors = ref({});

    // Submission state
    const isSubmitting = ref(false);
    const isRoomSubmitting = ref(false);
    const isRoomEditing = ref(false);
    const isStationSubmitting = ref(false);
    const isStationEditing = ref(false);

    // Get working event from store
    const workingEvent = computed(() => eventStore.workingEvent);

    // Watch for changes to set organization name
    watch(() => workingEvent.value, (newVal) => {
      if (newVal && newVal.organization) {
        organizationName.value = newVal.organization.organization_name;
      }
    }, {immediate: true});

    // Validate event form
    const validateEventForm = () => {
      formErrors.value = {};
      let isValid = true;

      if (!workingEvent.value.organizationsID) {
        formErrors.value.organization = "Organization is required";
        isValid = false;
      }

      if (!workingEvent.value.event_title || workingEvent.value.event_title.trim() === "") {
        formErrors.value.title = "Event title is required";
        isValid = false;
      }

      if (!workingEvent.value.event_start_date) {
        formErrors.value.startDate = "Start date is required";
        isValid = false;
      }

      if (!workingEvent.value.event_end_date) {
        formErrors.value.endDate = "End date is required";
        isValid = false;
      } else if (workingEvent.value.event_start_date && workingEvent.value.event_end_date) {
        const startDate = new Date(workingEvent.value.event_start_date);
        const endDate = new Date(workingEvent.value.event_end_date);
        if (endDate < startDate) {
          formErrors.value.endDate = "End date must be after start date";
          isValid = false;
        }
      }

      if (!workingEvent.value.event_location || workingEvent.value.event_location.trim() === "") {
        formErrors.value.location = "Event location is required";
        isValid = false;
      }

      return isValid;
    };

    // Update event
    const updateEvent = async () => {
      if (!validateEventForm()) {
        return;
      }

      isSubmitting.value = true;
      try {
        const response = await http.patch("/Event", workingEvent.value);

        if (response.data && response.data.status === 200) {
          // Update store with successful response
          eventStore.updateEventArray(workingEvent.value);

          // Show success message (could use toast or other notification)
          console.log("Event updated successfully");
        } else {
          throw new Error("Failed to update event");
        }
      } catch (error) {
        console.error("Error updating event:", error);
        errorMessage.value = "Failed to update event. Please try again.";
        error.value = true;
      } finally {
        isSubmitting.value = false;
      }
    };

    // Organization search
    const searchOrg = async () => {
      if (organizationName.value.length >= 3) {
        try {
          const response = await http.get("/public/search/", {
            params: {
              searchClass: "Organization",
              searchMethod: "searchByNames",
              term: organizationName.value
            },
          });

          if (response.data && response.data.record) {
            orgSearchArray.value = response.data.record;
          } else {
            orgSearchArray.value = [];
          }
        } catch (error) {
          console.error("Error searching organizations:", error);
          orgSearchArray.value = [];
        }
      } else {
        orgSearchArray.value = [];
      }
    };

    // Select organization
    const selectOrg = (org) => {
      if (org && org.organizationsID) {
        workingEvent.value.organizationsID = org.organizationsID;
        organizationName.value = org.organization_name;
        orgSearchArray.value = []; // Clear search results
      }
    };

    // Room management
    const addRoom = async () => {
      if (!newRoom.value || newRoom.value.trim() === "") {
        formErrors.value.newRoom = "Room name is required";
        return;
      }

      formErrors.value.newRoom = ""; // Clear error
      isRoomSubmitting.value = true;

      try {
        const data = {
          eventsID: workingEvent.value.eventsID,
          room_name: newRoom.value.trim()
        };

        const response = await http.post("/EventRoom", data);

        if (response.data && response.data.status === 200) {
          // Initialize rooms array if it doesn't exist
          if (!workingEvent.value.rooms) {
            workingEvent.value.rooms = [];
          }

          // Add new room to working event
          const newRoomData = response.data.record;
          workingEvent.value.rooms.push(newRoomData);

          // Update store
          eventStore.addRoomToEvent({
            eventsID: workingEvent.value.eventsID,
            newRoom: newRoomData
          });

          // Reset form
          newRoom.value = "";
        } else {
          throw new Error("Failed to add room");
        }
      } catch (error) {
        console.error("Error adding room:", error);
        formErrors.value.newRoom = "Failed to add room. Please try again.";
      } finally {
        isRoomSubmitting.value = false;
      }
    };

    const editRoom = (index) => {
      if (workingEvent.value.rooms && workingEvent.value.rooms[index]) {
        // Set editing flag
        workingEvent.value.rooms[index].isEditing = true;
      }
    };

    const saveRoom = async (index) => {
      if (!workingEvent.value.rooms || !workingEvent.value.rooms[index]) {
        return;
      }

      const room = workingEvent.value.rooms[index];

      if (!room.room_name || room.room_name.trim() === "") {
        // Don't allow empty room names
        return;
      }

      isRoomEditing.value = true;

      try {
        const response = await http.patch("/EventRoom", {
          event_roomsID: room.event_roomsID,
          room_name: room.room_name.trim()
        });

        if (response.data && response.data.status === 200) {
          // Update room data
          workingEvent.value.rooms[index] = {
            ...response.data.record,
            isEditing: false
          };
        } else {
          throw new Error("Failed to update room");
        }
      } catch (error) {
        console.error("Error updating room:", error);
        // Revert editing state
        workingEvent.value.rooms[index].isEditing = false;
      } finally {
        isRoomEditing.value = false;
      }
    };

    const removeRoom = async (index) => {
      if (!workingEvent.value.rooms || !workingEvent.value.rooms[index]) {
        return;
      }

      const room = workingEvent.value.rooms[index];

      if (confirm(`Are you sure you want to delete room "${room.room_name}"?`)) {
        isRoomEditing.value = true;

        try {
          const response = await http.delete(`/EventRoom/${room.event_roomsID}`);

          if (response.data && response.data.status === 200) {
            // Remove room from array
            workingEvent.value.rooms.splice(index, 1);
          } else {
            throw new Error("Failed to delete room");
          }
        } catch (error) {
          console.error("Error deleting room:", error);
        } finally {
          isRoomEditing.value = false;
        }
      }
    };

    // Sync Station management
    const addStation = async () => {
      if (!newSyncStationName.value || newSyncStationName.value.trim() === "") {
        formErrors.value.newStation = "Station name is required";
        return;
      }

      formErrors.value.newStation = ""; // Clear error
      isStationSubmitting.value = true;

      try {
        const data = {
          eventsID: workingEvent.value.eventsID,
          station_name: newSyncStationName.value.trim()
        };

        const response = await http.post("/SyncStation", data);

        if (response.data && response.data.status === 200) {
          // Initialize syncStations array if it doesn't exist
          if (!workingEvent.value.syncStations) {
            workingEvent.value.syncStations = [];
          }

          // Add new station to working event
          workingEvent.value.syncStations.push(response.data.record);

          // Reset form
          newSyncStationName.value = "";
        } else {
          throw new Error("Failed to add sync station");
        }
      } catch (error) {
        console.error("Error adding sync station:", error);
        formErrors.value.newStation = "Failed to add sync station. Please try again.";
      } finally {
        isStationSubmitting.value = false;
      }
    };

    const editStation = (index) => {
      if (workingEvent.value.syncStations && workingEvent.value.syncStations[index]) {
        // Set editing flag
        workingEvent.value.syncStations[index].isEditing = true;
      }
    };

    const saveStation = async (index) => {
      if (!workingEvent.value.syncStations || !workingEvent.value.syncStations[index]) {
        return;
      }

      const station = workingEvent.value.syncStations[index];

      if (!station.station_name || station.station_name.trim() === "") {
        // Don't allow empty station names
        return;
      }

      isStationEditing.value = true;

      try {
        const response = await http.patch("/SyncStation", {
          sync_stationsID: station.sync_stationsID,
          station_name: station.station_name.trim()
        });

        if (response.data && response.data.status === 200) {
          // Update station data
          workingEvent.value.syncStations[index] = {
            ...response.data.record,
            isEditing: false
          };
        } else {
          throw new Error("Failed to update sync station");
        }
      } catch (error) {
        console.error("Error updating sync station:", error);
        // Revert editing state
        workingEvent.value.syncStations[index].isEditing = false;
      } finally {
        isStationEditing.value = false;
      }
    };

    const removeStation = async (index) => {
      if (!workingEvent.value.syncStations || !workingEvent.value.syncStations[index]) {
        return;
      }

      const station = workingEvent.value.syncStations[index];

      if (confirm(`Are you sure you want to delete sync station "${station.station_name}"?`)) {
        isStationEditing.value = true;

        try {
          const response = await http.delete(`/SyncStation/${station.sync_stationsID}`);

          if (response.data && response.data.status === 200) {
            // Remove station from array
            workingEvent.value.syncStations.splice(index, 1);
          } else {
            throw new Error("Failed to delete sync station");
          }
        } catch (error) {
          console.error("Error deleting sync station:", error);
        } finally {
          isStationEditing.value = false;
        }
      }
    };

    // Fetch event data on component mount
    onMounted(async () => {
      loading.value = true;
      error.value = false;

      try {
        // Validate that we have an ID parameter
        if (!route.params.ID) {
          throw new Error("Missing event ID parameter");
        }

        const response = await http.get(`/Event/${route.params.ID}`);

        if (response.data && response.data.status === 200) {
          // Set event data in store
          eventStore.setWorkingEvent(response.data.record);

          // Initialize organization name
          if (response.data.record.organization) {
            organizationName.value = response.data.record.organization.organization_name;
          }
        } else {
          throw new Error("Could not fetch event details");
        }
      } catch (err) {
        console.error("Error loading event:", err);
        error.value = true;
        errorMessage.value = err.message || "Could not fetch event details";
      } finally {
        loading.value = false;
      }
    });

    return {
      workingEvent,
      loading,
      error,
      errorMessage,
      newRoom,
      newSyncStationName,
      organizationName,
      orgSearchArray,
      formErrors,
      isSubmitting,
      isRoomSubmitting,
      isRoomEditing,
      isStationSubmitting,
      isStationEditing,
      updateEvent,
      searchOrg,
      selectOrg,
      addRoom,
      editRoom,
      saveRoom,
      removeRoom,
      addStation,
      editStation,
      saveStation,
      removeStation
    };
  }
};
</script>

<style scoped>
.text-danger {
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
</style>
