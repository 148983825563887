import { http } from "@/api";

class GroupService {

    getAll() {
        return http.get('/Group/');
    }
}

export default new GroupService();
