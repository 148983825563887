<template>
  <div id="eventRoomScheduleView">
    <div>
      <div class="row">
        <div class="col-12"></div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h6 class="mb-0">
                <i class="bi bi-calendar2-event me-1"></i>
                {{ roomData?.room_name || 'Loading...' }} |
                {{ eventData?.event_title || 'Loading...' }}
              </h6>
              <div class="dropdown">
                <a href="#" class="dropdown-toggle" id="roomDropdown" data-bs-toggle="dropdown">
                  <i class="bi bi-geo-alt-fill me-1"></i> {{ roomData?.room_name || 'Loading...' }}
                </a>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="roomDropdown">
                  <li v-for="room in availableRooms" :key="room.event_roomsID">
                    <button class="dropdown-item" @click="changeRoom(room.event_roomsID)"
                            type="button">
                      {{ room.room_name }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body">


              <div v-if="presentationsArray && Object.keys(presentationsArray).length">
                <ul class="nav nav-tabs" id="scheduleTabs">
                  <li class="nav-item" v-for="(value, key) in presentationsArray" :key="key">
                    <button
                      class="nav-link"
                      :class="{ 'active bg-dark text-white': selectedTabDate === key, 'border border-bottom-0 mx-1': selectedTabDate !== key  }"
                      @click="setTabDate(key)"
                    >
                      {{ formatTabDate(key) }}
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="nav-link disabled text-danger">
                      Update in: {{ updateTimer }}s
                    </button>
                  </li>
                </ul>

                <div class="tab-content">
                  <div v-for="(value, key) in presentationsArray" :key="key" class="tab-pane fade"
                       :class="{ 'show active': selectedTabDate === key }">
                    <table class="table table-responsive table-bordered table-dark">
                      <thead>
                      <tr>
                        <th class="ps-3 td-download">
                          <input type="checkbox" @click="toggleCheckAll" v-model="isCheckAll"/>
                        </th>
                        <th class="td-time">Time</th>
                        <th class="td-presenter">Name</th>
                        <th class="td-type">Type</th>
                        <th class="td-slide">ARS</th>
                        <th class="td-title">Title</th>
                        <th class="td-rev">Revision</th>
                        <th class="td-buttons">Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="presentation in value" :key="presentation.presentation_scheduleID">

                        <!-- Admin Notes Row (Only shown if a note exists) -->
                        <tr v-if="presentation.admin_notes">
                          <td colspan="8" class="note-td">
                            <i class="bi bi-sticky"></i> {{ presentation.admin_notes }}
                          </td>
                        </tr>

                        <!-- Presentation Row -->

                          <tr
                              :ref="el => setRowRef(el, presentation)"
                              :class="getRowClass(presentation)">

                            <td class="td-download">
                              <input type="checkbox"
                                     v-if="presentation.presentationFile"
                                     v-model="downloadIDs"
                                     :value="presentation.presentation_scheduleID"
                                     @change="handleDownloadCheckbox"/>
                            </td>

                            <td class="td-time" contenteditable="true"
                                @keydown.enter="updateTime($event, presentation)"
                                @blur="updateTime($event, presentation)">
                              {{ presentationTime(presentation.presentation_time) }}
                            </td>

                            <td class="td-presenter">
                              <a v-if="presentation.presenter"
                                 :href="getPresenterLink(presentation)"
                                 target="_blank">
                                {{ presentation.presenter.presenters_lname }} {{ presentation.presenter.presenters_fname }}
                              </a>
                            </td>

                            <td class="td-type">
                              <em class="small" v-if="presentation.presentationFile">
                                {{ getFileType(presentation.presentationFile.file_typesID) }}
                              </em>
                            </td>

                            <td class="td-slide" contenteditable="true"
                                @keydown.enter="updateSlideNumber($event, presentation)"
                                @blur="updateSlideNumber($event, presentation)">
                              {{ presentation.ars_slide_numbers }}
                            </td>

                            <td class="td-title" contenteditable="true"
                                @keydown.enter="updateTitle($event, presentation)"
                                @blur="updateTitle($event, presentation)">
                              {{ presentation.presentation_title }}
                            </td>

                            <td class="td-rev">{{ revisionCount(presentation) }}</td>

                            <td class="td-buttons">
                              <button @click="createNoteClicked(presentation)" class="btn btn-link"
                                      data-bs-toggle="modal" data-bs-target="#createNoteModal">
                                <i class="bi bi-pencil-square"></i>
                              </button>
                              <button @click="deletePresentationClicked(presentation)" class="btn btn-link text-danger">
                                <i class="bi bi-trash"></i>
                              </button>
                            </td>
                        </tr>
                      </template>
                      </tbody>



                      <!-- Footer with Download Button -->
                      <tfoot>
                        <tr>
                          <td colspan="8">
                            <button
                              type="button"
                              class="btn btn-warning mt-3"
                              v-if="downloadIDs.length > 0"
                              @click.stop.prevent="downloadFiles">
                              <i class="bi bi-download"></i> Download Selected Files
                            </button>
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                </div>
              </div>
              <p v-else class="alert alert-danger">No presentation schedule found for this room.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="downloadModal" data-bs-backdrop="static" data-bs-keyboard="false"
         tabindex="-1"
         aria-labelledby="downloadModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadModalLabel">Download in Progress</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <h4>Your Download is Being Prepared</h4>
            <hr>
            <p>A new tab has been opened and your presentations are being compressed to a ZIP
              archive.</p>
            <p>Once complete you will see a system message from your browser.<br>
              It will ask if you want to save the ZIP file or open it.</p>
            <p>
              Inside the zip file will be individual directories for each presentations<br>
              the folder will look like "11_00_Doe_John" (24 hour formatted time followed by last
              and first name)
            </p>
            <p>
              Pro Tip: have a folder on your desktop named today's date:
              <em>(12-25)</em> and drag the folders from the zip to this directory.
              If you already downloaded a presentation and are replacing it the system will ask you
              if you want to
              overwrite the current file in the directory.
              Say Yes!
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="createNoteModal" tabindex="-1"
         aria-labelledby="createNoteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="createNoteModalLabel">Add Note</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">
        <textarea
          ref="noteInputField"
          v-model="createNoteText"
          class="form-control"
          rows="4"
          placeholder="Enter your note here"
        ></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="processNoteSave"
              data-bs-dismiss="modal"
            >
              Save Note
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {ref, computed, watch, onMounted, nextTick, onUnmounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useEventStore} from "@/store/modules/Events"; // Pinia Event Store
import {useSyncMachineStore} from "@/store/modules/SyncMachine"; // Pinia Sync Machine Store
import dayjs from "dayjs";
import {http} from "@/api"; // Use the configured HTTP client

export default {
  name: "EventRoomSchedule",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const eventStore = useEventStore(); // Using Pinia for events
    const syncStore = useSyncMachineStore(); // Using Pinia for syncMachine

    // Reactive state
    const event_roomsID = ref(route.params.ID);
    const presentationsArray = ref({});
    const selectedTabDate = ref("");
    const updateTimer = ref(15);
    const isSyncMachine = ref(false);
    const downloadIDs = ref([]);
    const isCheckAll = ref(false);
    const availableSyncStations = ref([]);
    const highlightPresentationID = ref(null);
    const createNoteText = ref("");
    const createNotePresentation = ref(null);

    // Computed properties
    const eventData = computed(() => eventStore.workingEvent || {});
    const roomData = computed(() => eventStore.workingRoom || {});
    const syncStation = computed(() => syncStore.getSyncMachine || {});
    const availableRooms = computed(() => {
      if (!eventData.value || !eventData.value.rooms || !roomData.value) {
        return [];
      }
      return eventData.value.rooms.filter(r => r.event_roomsID !== roomData.value.event_roomsID) || [];
    });


    // Methods
    const fetchSchedule = async () => {
      try {
        console.log("Fetching schedule...");

        if (!event_roomsID.value) {
          console.error("No room ID provided");
          return;
        }

        const response = await http.get("/EventRoomSchedule", {
          params: {event_roomsID: event_roomsID.value}
        });

        if (response.data && response.data.record) {
          eventStore.setWorkingEvent(response.data.record.event || {});
          eventStore.setWorkingRoom(response.data.record.room || {});
          presentationsArray.value = response.data.record.presentations || {};

          const dates = Object.keys(presentationsArray.value);

          // Auto-select first tab if no specific presentation is highlighted
          if (dates.length > 0 && !selectedTabDate.value) {
            selectedTabDate.value = dates[0];
          }

          // Check for highlighted presentation
          const presenterScheduleID = route.query.presenter_scheduleID;

          if (presenterScheduleID) {
            highlightPresentationID.value = Number(presenterScheduleID);
          }

          // Find the correct tab for the highlighted presentation
          if (highlightPresentationID.value) {
            for (const date of dates) {
              const presentation = presentationsArray.value[date].find(
                (p) => p.presentation_scheduleID === highlightPresentationID.value
              );

              if (presentation) {
                selectedTabDate.value = date;
                nextTick(() => scrollToHighlightedRow()); // Ensure DOM is updated
                break;
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching schedule:", error);
      }
    };

    const getRowClass = (presentation) => {
      const isHighlighted = presentation.presentation_scheduleID === highlightPresentationID.value;
      return {
        'flash-red': isHighlighted
      };
    };

    const rowRefs = ref({});

    const setRowRef = (el, presentation) => {
      if (el) {
        rowRefs.value[presentation.presentation_scheduleID] = el;
      }
    };

    const scrollToHighlightedRow = () => {
      // Use a longer timeout to ensure DOM has updated
      setTimeout(() => {
        const rowElement = rowRefs.value[highlightPresentationID.value];
        if (rowElement) {
          rowElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 500); // Longer timeout to ensure DOM has updated
    };

    const changeRoom = (roomsID) => {
      if (roomsID) {
        router.push({name: "EventRoomSchedule", params: {ID: roomsID}});
      }
    };

    const setTabDate = (date) => {
      selectedTabDate.value = date;
    };

    const presentationsWithNotes = computed(() => {
      if (!presentationsArray.value[selectedTabDate.value]) return [];
      return presentationsArray.value[selectedTabDate.value].filter(p => p.admin_notes);
    });

    const formatTabDate = (date) => {
      const day = dayjs(date);
      const dayNum = day.date();
      const suffix =
        dayNum > 3 && dayNum < 21 ? 'th' :
          ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][dayNum % 10];

      return `${day.format("ddd")}. ${dayNum}${suffix}`;
    };

    const presentationTime = (time) => {
      if (!time) return "--";
      return dayjs(time, "HH:mm:ss").format("h:mm A");
    };

    const revisionCount = (presentation) => {
      if (!presentation || !presentation.presentationFile || !presentation.presentationFile.file_revision) {
        return "--";
      }
      return `Rev: ${presentation.presentationFile.file_revision}`;
    };

    const getFileType = (fileTypesID) => {
      const types = {
        1: "PP-Win",
        2: "PP-Mac",
        3: "Video",
        4: "Keynote",
        5: "PDF",
        6: "Word",
      };
      return types[fileTypesID] || "N/A";
    };

    const toggleCheckAll = () => {
      if (isCheckAll.value) {
        // If already checked, uncheck all
        downloadIDs.value = [];
      } else {
        // Check all presentations for the current tab
        downloadIDs.value = [];
        const currentTabPresentations = presentationsArray.value[selectedTabDate.value] || [];
        currentTabPresentations.forEach(presentation => {
          if (presentation.presentation_scheduleID) {
            downloadIDs.value.push(presentation.presentation_scheduleID);
          }
        });
      }
      isCheckAll.value = !isCheckAll.value;
    };

    /** Missing **/
    const compareRevision = (presentation) => {
      if (!presentation?.presentationFile) return true;
      return presentation.syncData?.downloaded_revision === presentation.presentationFile.file_revision;
    };

    const getPresenterLink = (presentation) => {
      return `https://sync.meetingfiles.com/my-sync/auth.php?uuid=${presentation.presenter.uuid}&anyscreen=true&pid=${presentation.presentation_scheduleID}`;
    };

    const updateTime = async (e, presentation) => {
      const oldTime = presentation.presentation_time;
      const newTime = e.target.innerText.trim();

      if (oldTime === newTime) return; // Prevent unnecessary updates

      try {
        const response = await http.patch("/PresentationSchedule", {
          ID: presentation.presentation_scheduleID,
          presentation_time: newTime
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200 && response.data?.record) {
          // Update the presentation time from the server
          presentation.presentation_time = response.data.record.presentation_time;

          // Force Vue to detect the change and re-sort the schedule
          sortPresentations();
        } else {
          alert("Could not update the presentation on the server");
        }
      } catch (error) {
        alert("Error updating time." + error);
      }
    };

    const updateTitle = async (e, presentation) => {
      const oldTitle = presentation.presentation_title;
      const newTitle = e.target.innerText.trim();

      if (oldTitle === newTitle) return; // Prevent unnecessary updates

      try {
        const response = await http.patch("/PresentationSchedule", {
          ID: presentation.presentation_scheduleID,
          presentation_title: newTitle
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200 && response.data?.record) {
          presentation.presentation_title = response.data.record.presentation_title;
        } else {
          alert("Could not update the presentation on the server");
        }
      } catch (error) {
        alert("Error updating title." + error);
      }
    };

    const updateSlideNumber = async (e, presentation) => {
      const oldSlideNumber = presentation.ars_slide_numbers;
      const newSlideNumber = e.target.innerText.trim();

      if (oldSlideNumber === newSlideNumber) return; // Prevent unnecessary updates

      try {
        const response = await http.patch("/PresentationSchedule", {
          ID: presentation.presentation_scheduleID,
          ars_slide_numbers: newSlideNumber
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200 && response.data?.record) {
          presentation.ars_slide_numbers = response.data.record.ars_slide_numbers;
        } else {
          alert("Could not update the slide number on the server");
        }
      } catch (error) {
        alert("Error updating slide number." + error);
      }
    };

    const sortPresentations = () => {
      if (!presentationsArray.value[selectedTabDate.value]) return;

      presentationsArray.value[selectedTabDate.value] = [
        ...presentationsArray.value[selectedTabDate.value].sort((a, b) => {
          return a.presentation_time.localeCompare(b.presentation_time);
        })
      ];
    };
    const handleDownloadCheckbox = () => {
      isCheckAll.value = downloadIDs.value.length === (presentationsArray.value[selectedTabDate.value]?.length || 0);
    };

    const createNoteClicked = (presentation) => {
      createNotePresentation.value = presentation;
      createNoteText.value = presentation.admin_notes || "";
      createNoteText.value = presentation.admin_notes || "";

      // Use nextTick to ensure DOM is updated before we try to access the modal
      nextTick(() => {
        const modal = document.getElementById("createNoteModal");
        if (modal) {
          // Add event listener for when modal is fully shown
          modal.addEventListener("shown.bs.modal", () => {
            // Access the DOM element directly to ensure we get the actual element
            const textarea = document.querySelector("#createNoteModal textarea");
            if (textarea) {
              textarea.focus();
            }
          }, { once: true }); // Ensure the event fires only once per modal open
        }
      });
    };

    const processNoteSave = async () => {
      try {
        const response = await http.patch("/PresentationSchedule", {
          ID: createNotePresentation.value.presentation_scheduleID,
          admin_notes: createNoteText.value
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.status === 200) {
          createNotePresentation.value.admin_notes = createNoteText.value;
        } else {
          alert("Error saving note.");
        }
      } catch (error) {
        alert("Error saving note." + error);
      }
    };

    const deletePresentationClicked = async (presentation) => {
      if (!confirm(`Warning! You are about to delete this presentation.\n${presentation.presentation_title}`)) return;
      try {
        const response = await http.delete("/PresentationSchedule", {
          params: {presentation_scheduleID: presentation.presentation_scheduleID}
        });
        if (response.status === 200) {
          presentationsArray.value[selectedTabDate.value] = presentationsArray.value[selectedTabDate.value].filter(
            p => p.presentation_scheduleID !== presentation.presentation_scheduleID
          );
        }
      } catch (error) {
        alert("Error deleting presentation." + error);
      }
    };

    const downloadFiles = () => {
      if (downloadIDs.value.length === 0) {
        alert("No Presentations Selected");
        return;
      }

      const syncStationID = syncStation.value?.sync_stationsID || "";
      const url = `https://sync.meetingfiles.com/api/public/download/?${downloadIDs.value
        .map((n, index) => `scheduleIDs[${index}]=${n}`)
        .join("&")}&room=${encodeURIComponent(roomData.value.room_name)}&date=${encodeURIComponent(selectedTabDate.value)}&syncStationID=${syncStationID}`;

      console.log("Download URL:", url);
      window.open(url, "_blank");

      //isCheckAll.value = false;
      toggleCheckAll();
    };

    let timerInterval = null;

    const startUpdateTimer = () => {
      timerInterval = setInterval(() => {
        updateTimer.value--;
        if (updateTimer.value <= 0) {
          updateTimer.value = 15;
          fetchSchedule();
        }
      }, 1000);
    };

    // Watch for route changes
    watch(
      () => route.params.ID,
      (newID) => {
        if (newID) {
          event_roomsID.value = newID;
          fetchSchedule();
        } else {
          console.error("No ID parameter in route");
        }
      },
      {immediate: true}
    );

    let highlightWatcher = null;

// Replace your existing watch for highlightPresentationID with this
    watch(
      () => route.query.presenter_scheduleID,
      (newID) => {
        if (newID) {
          // Set highlighted ID
          highlightPresentationID.value = Number(newID);

          // Only set up the watcher if we don't already have one
          if (!highlightWatcher) {
            highlightWatcher = watch(highlightPresentationID, () => {
              console.log("Highlight ID updated:", highlightPresentationID.value);
              if (highlightPresentationID.value) {
                nextTick(() => scrollToHighlightedRow());
              }
            }, { immediate: true });
          }

          // Clear the query parameter without triggering a navigation
          const { query } = router.currentRoute.value;
          const newQuery = { ...query };
          delete newQuery.presenter_scheduleID;
          router.replace({ query: newQuery });
        }
      },
      { immediate: true }
    );

    // Check if already syncing on component mount
    onMounted(() => {
      startUpdateTimer();
    });
    onUnmounted(() => {
      if (timerInterval) clearInterval(timerInterval);
    });

    return {
      eventData,
      roomData,
      syncStation,
      isSyncMachine,
      presentationsArray,
      selectedTabDate,
      availableRooms,
      updateTimer,
      downloadIDs,
      isCheckAll,
      availableSyncStations,
      downloadFiles,
      changeRoom,
      setTabDate,
      formatTabDate,
      presentationTime,
      revisionCount,
      getFileType,
      toggleCheckAll,
      compareRevision,
      getPresenterLink,
      updateTime,
      updateTitle,
      handleDownloadCheckbox,
      createNoteClicked,
      processNoteSave,
      deletePresentationClicked,
      updateSlideNumber,
      setRowRef,
      getRowClass,
      presentationsWithNotes,
      highlightPresentationID,
      createNoteText,
      createNotePresentation
    };
  },
};
</script>

<style scoped>
.note-td {
  background-color: #fce587;
  color: #282d36;
  padding: 5px;
  border-left: 5px solid #f39c12;
}

.td-slide {
  width: 75px;
  text-align: center;
}

.td-time {
  width: 100px;
  text-align: left;
}

.td-presenter {
  width: 250px;
}

.td-type {
  width: 50px;
  text-align: center;
}

.td-title {

}

.td-rev {
  width: 50px;
  text-align: center;
}

.td-buttons {
  width: 100px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;

}

.td-download {
  width: 25px;
  text-align: center;
}

@keyframes flashBackground {
  0% {
    background-color: white;
  }
  50% {
    background-color: #ff6666;
  }
  100% {
    background-color: #212529; /* This is Bootstrap's table-dark color */
  }
}

.flash-red td {
  animation: flashBackground 1s ease-in-out 3;
  animation-fill-mode: forwards;
}
</style>
