import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from '@/components/AppFrame.vue';
import DashboardHome from '@/views/Dashboard/Dashboard.vue';
import PresenterView from '@/views/Presenter/PresenterView.vue';
import PresenterScheduleView from '@/views/Presenter/PresenterScheduleView.vue';
import EventsView from '@/views/Events/EventsView.vue';
import OrganizationsView from '@/views/Organizations/OrganizationsView.vue';
import UsersAdminView from '@/views/Admin/UsersAdminView.vue';
import GroupsAdminView from '@/views/Admin/GroupsAdminView.vue';
import EventDetailView from '@/views/Events/EventDetailView.vue';
import ScheduleUploadView from '@/views/Events/ScheduleUploadView.vue';
import EventRoomSchedule from '@/views/Events/EventRoomSchedule.vue';
import HomeComponent from '@/components/HomeComponent.vue';
import { useUserStore } from '@/store/modules/UseUserStore.js';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent,
    meta: { title: 'Meeting Files SYNC!' }
  },
  {
    path: '/app',
    redirect: { name: 'DashboardHome' },
    meta: { requiresAuth: true, title: 'SYNC!' }
  },
  {
    path: '/app',
    component: Dashboard,
    children: [
      { path: 'dashboard', name: 'DashboardHome', component: DashboardHome, meta: { requiresAuth: true, title: 'SYNC!' } },
      { path: 'presenters', name: 'PresenterView', component: PresenterView, meta: { requiresAuth: true, title: 'SYNC!' } },
      { path: 'presenters/schedule/:ID', name: 'PresenterScheduleView', component: PresenterScheduleView, meta: { requiresAuth: true, title: 'SYNC!' } },
      { path: 'organizations', name: 'OrganizationsView', component: OrganizationsView, meta: { requiresAuth: true, title: 'SYNC!' } },
      { path: 'events', name: 'EventsView', component: EventsView, meta: { requiresAuth: true, title: 'SYNC!' } },
      { path: 'events/view/:ID', name: 'EventDetailView', component: EventDetailView, meta: { requiresAuth: true, title: 'SYNC!' } },
      { path: 'events/schedule/view/:ID', name: 'EventRoomSchedule', component: EventRoomSchedule, meta: { requiresAuth: true, title: 'SYNC!' } },
      { path: 'events/schedule/upload/:ID', name: 'ScheduleUploadView', component: ScheduleUploadView, meta: { requiresAuth: true, title: 'SYNC!' } },
      { path: 'admin/users', name: 'UsersAdminView', component: UsersAdminView, meta: { requiresAuth: true, adminOnly: true, title: 'SYNC!' } },
      { path: 'admin/groups', name: 'GroupsAdminView', component: GroupsAdminView, meta: { requiresAuth: true, adminOnly: true, title: 'SYNC!' } }
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  // Check for required params
  const requiresIdParam = to.matched.some(record =>
    record.path.includes(':ID') && !to.params.ID);

  if (requiresIdParam) {
    console.warn(`Navigation to ${to.name} attempted without required ID parameter`);
    next('/app/dashboard'); // Fallback to a safe route
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !userStore.isUserAuthenticated) {
    next({ name: 'Home' });
  } else if (to.matched.some(record => record.meta.adminOnly) && userStore.userLevel !== 1) {
    console.log('You do not have permission to access this area.');
    next(false);
  } else {
    next();
  }
});

export default router;
