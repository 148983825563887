import { defineStore } from "pinia";
import groupService from "@/service/GroupService";

export const useGroupStore = defineStore("groupStore", {
  state: () => ({
    groupsArray: null
  }),

  getters: {
    getGroupsArray: (state) => state.groupsArray
  },

  actions: {
    async setGroupsData() {
      try {
        const response = await groupService.getAll();
        if (response.data.status === 200) {
          this.groupsArray = response.data.record;
        }
      } catch (error) {
        console.error(error);
      }
    },

    addGroupToArray(group) {
      if (this.groupsArray) {
        this.groupsArray.push(group);
      }
    },

    addUserToGroup(user) {
      if (Array.isArray(this.groupsArray)) {
        const group = this.groupsArray.find(g => g.groupsID === user.groupsID);
        if (group) {
          if (Array.isArray(group.users)) {
            group.users.push(user);
          } else {
            group.users = [user];
          }
        }
      }
    }
  }
});
