<template>
  <div id="presenterView">
    <!-- Search Section -->
    <div class="card mb-3">
      <div class="card-header">
        <h4>Search Presenters</h4>
      </div>
      <div class="card-body">
        <div class="mb-3">
          <label class="form-label" for="searchInput">Enter Presenter Name</label>
          <input
            type="text"
            id="searchInput"
            class="form-control"
            v-model="searchName"
            @keyup="searchPresenters"
            placeholder="Enter at least 3 characters"
            aria-describedby="searchHelp"
          />
          <small id="searchHelp" class="form-text text-muted">Start typing a presenter name (at least 3 characters)</small>
        </div>

        <!-- Search Results -->
        <div v-if="isSearching" class="text-center my-3">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Searching...</span>
          </div>
        </div>

        <div v-else-if="showSearchResults">
          <table class="table">
            <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="searchResult in searchResults" :key="searchResult.presentersID">
              <td>{{ searchResult.presenters_fname }}</td>
              <td>{{ searchResult.presenters_lname }}</td>
              <td>
                <a :href="'mailto:' + searchResult.presenters_email">
                  {{ searchResult.presenters_email }}
                </a>
              </td>
              <td>
                <div class="btn-group">
                  <button
                    class="btn btn-sm btn-secondary"
                    @click="editPresenter(searchResult)"
                    aria-label="Edit presenter"
                  >
                    <i class="bi bi-pencil-square"></i> edit
                  </button>
                  <button
                    class="btn btn-sm btn-secondary"
                    @click="goToSchedule(searchResult)"
                    aria-label="View presenter schedule"
                  >
                    <i class="bi bi-file-earmark-plus"></i> schedule
                  </button>
                  <button
                    class="btn btn-sm btn-secondary"
                    @click="goToSync(searchResult)"
                    aria-label="Go to sync"
                  >
                    <i class="bi bi-link-45deg"></i> sync
                  </button>
                  <button
                    class="btn btn-sm btn-secondary"
                    @click="copyLink(searchResult)"
                    aria-label="Copy presenter link"
                  >
                    <i class="bi bi-clipboard"></i> copy link
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <p v-if="showNoSearchResultsAlert" class="alert alert-warning">
          <i class="bi bi-exclamation-triangle-fill"></i> No results found for "{{ searchName }}"
        </p>
      </div>
    </div>

    <!-- Presenter Form Section -->
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h4 v-if="!editing">Add a New Presenter</h4>
        <h4 v-else>Edit Presenter: {{ newPresenterFname }} {{ newPresenterLname }}</h4>
        <button
          v-if="editing"
          @click="cancelEdit"
          class="btn btn-outline-secondary"
          aria-label="Cancel editing"
        >
          <i class="bi bi-x-circle me-1"></i> Cancel
        </button>
      </div>
      <div class="card-body">
        <!-- Form Messages -->
        <div
          v-if="showMsg"
          class="alert"
          :class="[isError ? 'alert-danger' : 'alert-success']"
          role="alert"
        >
          <i class="bi" :class="[isError ? 'bi-exclamation-octagon-fill' : 'bi-check-circle-fill']"></i>
          {{ formMsg }}
        </div>

        <!-- Form -->
        <form @submit.prevent="validateAndSubmit">
          <div class="mb-3">
            <label for="firstName" class="form-label">First Name</label>
            <input
              type="text"
              id="firstName"
              class="form-control"
              v-model="newPresenterFname"
              :class="{'is-invalid': formErrors.fname}"
              required
              :disabled="isSubmitting"
            />
            <div v-if="formErrors.fname" class="invalid-feedback">
              {{ formErrors.fname }}
            </div>
          </div>

          <div class="mb-3">
            <label for="lastName" class="form-label">Last Name</label>
            <input
              type="text"
              id="lastName"
              class="form-control"
              v-model="newPresenterLname"
              :class="{'is-invalid': formErrors.lname}"
              required
              :disabled="isSubmitting"
            />
            <div v-if="formErrors.lname" class="invalid-feedback">
              {{ formErrors.lname }}
            </div>
          </div>

          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input
              type="email"
              id="email"
              class="form-control"
              v-model="newPresenterEmail"
              :class="{'is-invalid': formErrors.email}"
              required
              :disabled="isSubmitting"
            />
            <div v-if="formErrors.email" class="invalid-feedback">
              {{ formErrors.email }}
            </div>
          </div>

          <div class="mb-3">
            <input type="hidden" v-model="newPresentersID" />
            <button
              type="submit"
              class="btn btn-success me-2"
              :disabled="isSubmitting"
            >
              <span v-if="isSubmitting">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ editing ? 'Updating...' : 'Creating...' }}
              </span>
              <span v-else>
                {{ editing ? 'Update Presenter' : 'Create Presenter' }}
              </span>
            </button>
            <button
              v-if="editing"
              type="button"
              class="btn btn-outline-secondary"
              @click="cancelEdit"
              :disabled="isSubmitting"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Copy Success Toast -->
    <div
      v-if="showCopySuccess"
      class="position-fixed bottom-0 end-0 p-3"
      style="z-index: 5000;"
    >
      <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header bg-success text-white">
          <i class="bi bi-clipboard-check me-2"></i>
          <strong class="me-auto">Success</strong>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
            @click="showCopySuccess = false"
          ></button>
        </div>
        <div class="toast-body">
          Presenter link copied to clipboard.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { http } from "@/api"; // Use configured HTTP client

export default {
  name: "PresenterView",
  setup() {
    const route = useRoute();
    const router = useRouter();

    // Form state
    const newPresenterFname = ref("");
    const newPresenterLname = ref("");
    const newPresenterEmail = ref("");
    const newPresentersID = ref("");
    const formErrors = ref({});

    // UI state
    const showMsg = ref(false);
    const isError = ref(false);
    const formMsg = ref("");
    const editing = ref(false);
    const isSubmitting = ref(false);
    const showCopySuccess = ref(false);

    // Search state
    const searchName = ref("");
    const searchResults = ref([]);
    const showSearchResults = ref(false);
    const showNoSearchResultsAlert = ref(false);
    const isSearching = ref(false);

    // Form validation
    const validateForm = () => {
      formErrors.value = {};
      let isValid = true;

      if (!newPresenterFname.value.trim()) {
        formErrors.value.fname = "First name is required";
        isValid = false;
      }

      if (!newPresenterLname.value.trim()) {
        formErrors.value.lname = "Last name is required";
        isValid = false;
      }

      if (!newPresenterEmail.value.trim()) {
        formErrors.value.email = "Email is required";
        isValid = false;
      } else if (!isValidEmail(newPresenterEmail.value)) {
        formErrors.value.email = "Please enter a valid email address";
        isValid = false;
      }

      return isValid;
    };

    const isValidEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    const validateAndSubmit = () => {
      if (validateForm()) {
        createNewPresenter();
      }
    };

    // Create or update presenter
    const createNewPresenter = async () => {
      isSubmitting.value = true;
      showMsg.value = false;

      try {
        const presenterData = {
          presenters_fname: newPresenterFname.value.trim(),
          presenters_lname: newPresenterLname.value.trim(),
          presenters_email: newPresenterEmail.value.trim()
        };

        let response;
        if (editing.value) {
          presenterData.ID = newPresentersID.value; // Use consistent field name
          response = await http.patch("/Presenter", presenterData, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
        } else {
          response = await http.post("/Presenter", presenterData);
        }

        processNewPresenterResponse(response.data);
      } catch (error) {
        console.error("Error saving presenter:", error);
        isError.value = true;
        formMsg.value = "An error occurred. Please try again.";
        showMsg.value = true;
      } finally {
        isSubmitting.value = false;
      }
    };

    // Search for presenters
    const searchPresenters = async () => {
      // Clear previous results if search is too short
      if (searchName.value.length < 3) {
        showSearchResults.value = false;
        searchResults.value = [];
        showNoSearchResultsAlert.value = false;
        return;
      }

      isSearching.value = true;

      try {
        const response = await http.get("/public/search/", {
          params: {
            searchClass: "Presenter",
            searchMethod: "searchByNames",
            term: searchName.value
          }
        });

        processSearchResults(response.data);
      } catch (error) {
        console.error("Error searching presenters:", error);
        showSearchResults.value = false;
        showNoSearchResultsAlert.value = true;
      } finally {
        isSearching.value = false;
      }
    };

    // Process API responses
    const processNewPresenterResponse = (response) => {
      if (response.status === 200) {
        isError.value = false;
        formMsg.value = editing.value
          ? "Presenter updated successfully"
          : "New presenter created successfully";
        showMsg.value = true;

        // If adding a new presenter, clear the form
        if (!editing.value) {
          resetForm();
        }
      } else if (response.status === 409) {
        isError.value = true;
        formMsg.value = "A presenter with this email already exists.";
        showMsg.value = true;
      } else {
        isError.value = true;
        formMsg.value = response.message || "Unknown error occurred";
        showMsg.value = true;
      }
    };

    const processSearchResults = (data) => {
      if (data.record && data.record.length > 0) {
        showNoSearchResultsAlert.value = false;
        showSearchResults.value = true;
        searchResults.value = data.record;
      } else {
        showSearchResults.value = false;
        showNoSearchResultsAlert.value = true;
        searchResults.value = [];
      }
    };

    // UI actions
    const editPresenter = (presenter) => {
      editing.value = true;
      newPresenterFname.value = presenter.presenters_fname || "";
      newPresenterLname.value = presenter.presenters_lname || "";
      newPresenterEmail.value = presenter.presenters_email || "";
      newPresentersID.value = presenter.presentersID || "";

      // Scroll to form
      setTimeout(() => {
        document.querySelector('.card:last-child').scrollIntoView({behavior: 'smooth'});
      }, 100);
    };

    const cancelEdit = () => {
      editing.value = false;
      resetForm();
    };

    const resetForm = () => {
      newPresenterFname.value = "";
      newPresenterLname.value = "";
      newPresenterEmail.value = "";
      newPresentersID.value = "";
      formErrors.value = {};
    };

    const goToSchedule = (selectedPresenter) => {
      router.push({
        name: "PresenterScheduleView",
        params: {ID: selectedPresenter.presentersID}
      });
    };

    const goToSync = (presenter) => {
      if (!presenter.uuid) {
        isError.value = true;
        formMsg.value = "This presenter doesn't have a valid UUID.";
        showMsg.value = true;
        return;
      }

      const url = encodeURI(`https://sync.meetingfiles.com/my-sync/auth.php?anyscreen=true&uuid=${presenter.uuid}`);
      window.open(url, "_blank");
    };

    const copyLink = (presenter) => {
      if (!presenter.uuid || !presenter.presenters_pin) {
        isError.value = true;
        formMsg.value = "This presenter doesn't have a valid UUID or PIN.";
        showMsg.value = true;
        return;
      }

      const link = `https://sync.meetingfiles.com/my-sync/?uuid=${presenter.uuid}&pin=${presenter.presenters_pin}`;

      // Check if Clipboard API is supported
      if (navigator.clipboard) {
        navigator.clipboard.writeText(link)
          .then(() => {
            showCopySuccess.value = true;
            setTimeout(() => {
              showCopySuccess.value = false;
            }, 3000);
          })
          .catch((err) => {
            console.error("Failed to copy link:", err);
            // Fallback
            fallbackCopyTextToClipboard(link);
          });
      } else {
        // Fallback for browsers that don't support clipboard API
        fallbackCopyTextToClipboard(link);
      }
    };

    // Fallback clipboard function for older browsers
    const fallbackCopyTextToClipboard = (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;

      // Make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          showCopySuccess.value = true;
          setTimeout(() => {
            showCopySuccess.value = false;
          }, 3000);
        } else {
          throw new Error("Copy command was unsuccessful");
        }
      } catch (err) {
        console.error("Fallback: Failed to copy link", err);
        isError.value = true;
        formMsg.value = "Unable to copy to clipboard. Please copy this link manually: " + text;
        showMsg.value = true;
      }

      document.body.removeChild(textArea);
    };

    // Fetch presenter by ID
    const setPresenter = async (presentersID) => {
      try {
        const response = await http.get(`/Presenter/${presentersID}`);

        if (response.data && response.data.status === 200) {
          editPresenter(response.data.record);
        } else {
          isError.value = true;
          formMsg.value = "Could not find the requested presenter.";
          showMsg.value = true;
        }
      } catch (error) {
        console.error("Error fetching presenter:", error);
        isError.value = true;
        formMsg.value = "Error loading presenter information.";
        showMsg.value = true;
      }
    };

    // Watch for route parameter changes
    watch(() => route.params.ID, (newID) => {
      if (newID) {
        setPresenter(newID);
      } else {
        // If ID is removed, reset to add mode
        cancelEdit();
      }
    });

    // Initialize on component mount
    onMounted(() => {
      if (route.params.ID) {
        setPresenter(route.params.ID);
      }
    });

    return {
      // Form state
      newPresenterFname,
      newPresenterLname,
      newPresenterEmail,
      newPresentersID,
      formErrors,

      // UI state
      showMsg,
      isError,
      formMsg,
      editing,
      isSubmitting,
      showCopySuccess,

      // Search state
      searchName,
      searchResults,
      showSearchResults,
      showNoSearchResultsAlert,
      isSearching,

      // Methods
      validateAndSubmit,
      searchPresenters,
      editPresenter,
      cancelEdit,
      goToSchedule,
      goToSync,
      copyLink
    };
  }
};
</script>

<style scoped>
.toast {
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.invalid-feedback {
  display: block;
}
</style>
