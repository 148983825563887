import { http } from "@/api";

class UserService {
  getAll() {
    return http.get("/public/User/");
  }

  get(id) {
    return http.get(`/User/${id}`);
  }

  create(data) {
    return http.post("/User", data);
  }

  update(id, data) {
    return http.patch(`/User/${id}`, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  delete(id) {
    return http.delete(`/User/${id}`);
  }

  deleteAll() {
    return http.delete('/User');
  }

  findByTitle(title) {
    return http.get(`/User?title=${title}`);
  }

  authenticate(username, password){
    return http.post("/public/login/", {username: username, password: password});
  }

  checkAuthentication(){
    //sending this request should return the logged in users data
    return http.get('/User', { params: { getME: true } });
  }

  logOut(){
    return http.post("/public/logout/", {});
  }
}

export default new UserService();
