import axios from "axios";

const http = axios.create({
  baseURL: "https://sync.meetingfiles.com/api",
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data"
  }
});

export { http };

export default {
  install: (app, options = {}) => {
    const apiInstance = axios.create({
      baseURL: "https://sync.meetingfiles.com/api",
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      ...options
    });

    app.config.globalProperties.$api = apiInstance; // For Options API
    app.provide("api", apiInstance); // For Composition API
  }
};
