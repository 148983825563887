<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div v-else-if="error" class="alert alert-danger">
      {{ errorMessage }}
    </div>

    <div v-else-if="workingPresenter">
      <!-- Presenter Schedule Section -->
      <div id="presenterSchedule">
        <div class="card">
          <div class="card-header">
            <h4>Presenter Schedule for: {{ workingPresenter.presenters_fname }} {{ workingPresenter.presenters_lname }}</h4>
          </div>
          <div class="card-body">
            <div v-if="currentPresentationSchedule.length">
              <table class="table">
                <thead>
                <tr>
                  <th>Event/Room</th>
                  <th>Presentation</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="row in currentPresentationSchedule" :key="row.presentation_scheduleID">
                  <td>{{ row.event.event_title }}<br>{{ row.room.room_name }}</td>
                  <td>{{ row.presentation_title }}</td>
                  <td>{{ formatDate(row.presentation_date) }}</td>
                  <td>{{ formatTime(row.presentation_time) }}</td>
                  <td>
                    <div class="btn-group">
                      <button type="button" class="btn btn-secondary" @click="editSchedule(row)">
                        <i class="bi bi-pencil-square"></i> edit
                      </button>
                      <button type="button" class="btn btn-secondary" @click="goToSyncUploadPage(row)">
                        <img src="@/assets/images/sync-icon-white.png" width="16"/> sync
                      </button>
                      <router-link
                        :to="{
                          name: 'EventRoomSchedule',
                          params: {ID: row.room.event_roomsID},
                          query: {presenter_scheduleID: row.presentation_scheduleID}
                        }"
                        class="btn btn-secondary">
                        <i class="bi bi-calendar-date"></i> View Schedule
                      </router-link>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <p class="alert alert-warning">
                No current presentations for this presenter. Use the form below to add presentations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Presentation Form Section -->
      <div class="card mt-3">
        <div class="card-header">
          <h4 v-if="!isEditing">Add Presentation for {{ workingPresenter.presenters_fname }} {{ workingPresenter.presenters_lname }}</h4>
          <h4 v-else class="text-primary">Editing Schedule Entry</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="validateAndSubmit">
            <!-- Form validation errors -->
            <div v-if="formErrors.general" class="alert alert-danger mb-3">
              {{ formErrors.general }}
            </div>

            <!-- Event selection -->
            <div class="mb-3">
              <label class="form-label">Select the Event</label>
              <select
                v-model="newPresentation.eventsID"
                @change="selectEvent"
                class="form-select"
                :disabled="isSubmitting">
                <option value="">Select an Event</option>
                <option v-for="event in myGroupsEvents" :key="event.eventsID" :value="event.eventsID">
                  {{ event.event_title }}
                </option>
              </select>
              <div v-if="formErrors.eventsID" class="text-danger">{{ formErrors.eventsID }}</div>
            </div>

            <!-- Room selection -->
            <div class="mb-3" v-if="workingEvent">
              <label class="form-label">Event Room</label>
              <select
                v-model="newPresentation.event_roomsID"
                class="form-select"
                :disabled="isSubmitting">
                <option value="">Select a Room</option>
                <option v-for="room in workingEvent.rooms" :key="room.event_roomsID" :value="room.event_roomsID">
                  {{ room.room_name }}
                </option>
              </select>
              <div v-if="formErrors.event_roomsID" class="text-danger">{{ formErrors.event_roomsID }}</div>
            </div>

            <!-- Presentation type -->
            <div class="mb-3">
              <label class="form-label">Presentation Type</label>
              <select
                class="form-select"
                v-model="newPresentation.presentation_typesID"
                :disabled="isSubmitting">
                <option value="1">Oral Presentation</option>
                <option value="2">PDF Presentation</option>
                <option value="3">Poster Presentation</option>
                <option value="4">Video Presentation</option>
              </select>
              <div v-if="formErrors.presentation_typesID" class="text-danger">{{ formErrors.presentation_typesID }}</div>
            </div>

            <!-- Presentation title -->
            <div class="mb-3">
              <label class="form-label">Presentation Title</label>
              <input
                type="text"
                class="form-control"
                v-model="newPresentation.presentation_title"
                :disabled="isSubmitting"/>
              <div v-if="formErrors.presentation_title" class="text-danger">{{ formErrors.presentation_title }}</div>
            </div>

            <!-- Presentation date -->
            <div class="mb-3">
              <label class="form-label">Presentation Date</label>
              <input
                type="date"
                class="form-control"
                v-model="newPresentation.presentation_date"
                :min="minDate"
                :max="maxDate"
                :disabled="isSubmitting"/>
              <div v-if="formErrors.presentation_date" class="text-danger">{{ formErrors.presentation_date }}</div>
            </div>

            <!-- Presentation time -->
            <div class="mb-3">
              <label class="form-label">Presentation Time</label>
              <input
                type="time"
                class="form-control"
                v-model="newPresentation.presentation_time"
                :disabled="isSubmitting"/>
              <div v-if="formErrors.presentation_time" class="text-danger">{{ formErrors.presentation_time }}</div>
            </div>

            <!-- Form submission -->
            <div class="mb-3 d-flex">
              <button
                type="submit"
                class="btn btn-success me-2"
                :disabled="isSubmitting">
                <span v-if="isSubmitting">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {{ isEditing ? 'Updating...' : 'Submitting...' }}
                </span>
                <span v-else>{{ isEditing ? 'Update Presentation' : 'Add Presentation' }}</span>
              </button>

              <button
                v-if="isEditing"
                type="button"
                class="btn btn-secondary"
                @click="cancelEdit"
                :disabled="isSubmitting">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="alert alert-danger">Could not find presenter information. Please try again.</p>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { http } from "@/api"; // Use configured HTTP client
import eventService from "@/service/EventService";
import dayjs from "dayjs";

export default {
  name: "PresenterSchedule",
  setup() {
    const route = useRoute();

    // State
    const presentersID = ref(route.params.ID);
    const workingPresenter = ref(null);
    const currentPresentationSchedule = ref([]);
    const workingEvent = ref(null);
    const myGroupsEvents = ref([]);
    const isEditing = ref(false);
    const loading = ref(true);
    const error = ref(false);
    const errorMessage = ref("");
    const isSubmitting = ref(false);
    const formErrors = ref({});

    // Initial presentation state
    const defaultPresentation = {
      eventsID: "",
      event_roomsID: "",
      presentation_typesID: "1", // String to match select value
      presentation_title: "",
      presentation_date: null,
      presentation_time: null,
      presentersID: "",
      presentation_scheduleID: null
    };

    const newPresentation = ref({...defaultPresentation});

    // Set the presenter data
    const setPresenter = async () => {
      loading.value = true;
      error.value = false;

      try {
        if (!presentersID.value) {
          throw new Error("No presenter ID provided");
        }

        const response = await http.get(`/Presenter/${presentersID.value}`);

        if (response.data && response.data.status === 200) {
          workingPresenter.value = response.data.record;
          await Promise.all([
            setPresenterSchedule(),
            setMyGroupsEvents()
          ]);
        } else {
          throw new Error("Failed to fetch presenter information");
        }
      } catch (err) {
        console.error("Error fetching presenter:", err);
        error.value = true;
        errorMessage.value = err.message || "Failed to load presenter information";
      } finally {
        loading.value = false;
      }
    };

    // Fetch the presenter schedule
    const setPresenterSchedule = async () => {
      try {
        const response = await http.get("/PresentationSchedule", {
          params: {
            method: "getCurrentPresenterSchedule",
            presentersID: presentersID.value
          }
        });

        if (response.data && response.data.status === 200) {
          currentPresentationSchedule.value = response.data.record || [];
        } else {
          throw new Error("Failed to fetch presenter schedule");
        }
      } catch (error) {
        console.error("Error fetching schedule:", error);
        // Don't set global error for this - just log it and continue
      }
    };

    // Fetch events for the presenter's group
    const setMyGroupsEvents = async () => {
      try {
        const response = await eventService.getAll();

        if (response.data && response.data.status === 200) {
          myGroupsEvents.value = response.data.record || [];
        } else {
          throw new Error("Failed to fetch events");
        }
      } catch (error) {
        console.error("Error fetching events:", error);
        // Don't set global error for this - just log it and continue
      }
    };

    // Select an event from the list
    const selectEvent = () => {
      if (!newPresentation.value.eventsID) {
        workingEvent.value = null;
        return;
      }

      const selectedEvent = myGroupsEvents.value.find(
        event => event.eventsID === newPresentation.value.eventsID
      );

      if (selectedEvent) {
        workingEvent.value = selectedEvent;

        // Set default date to event start date
        newPresentation.value.presentation_date = selectedEvent.event_start_date;

        // Set default room if available
        if (selectedEvent.rooms && selectedEvent.rooms.length > 0) {
          newPresentation.value.event_roomsID = selectedEvent.rooms[0].event_roomsID;
        } else {
          newPresentation.value.event_roomsID = "";
        }
      } else {
        workingEvent.value = null;
      }
    };

    // Form validation
    const validateForm = () => {
      formErrors.value = {};
      let isValid = true;

      if (!newPresentation.value.eventsID) {
        formErrors.value.eventsID = "Please select an event";
        isValid = false;
      }

      if (!newPresentation.value.event_roomsID) {
        formErrors.value.event_roomsID = "Please select a room";
        isValid = false;
      }

      if (!newPresentation.value.presentation_title ||
        newPresentation.value.presentation_title.trim() === "") {
        formErrors.value.presentation_title = "Presentation title is required";
        isValid = false;
      }

      if (!newPresentation.value.presentation_date) {
        formErrors.value.presentation_date = "Presentation date is required";
        isValid = false;
      } else if (workingEvent.value) {
        const presentationDate = new Date(newPresentation.value.presentation_date);
        const startDate = new Date(workingEvent.value.event_start_date);
        const endDate = new Date(workingEvent.value.event_end_date);

        if (presentationDate < startDate || presentationDate > endDate) {
          formErrors.value.presentation_date = "Date must be within event dates";
          isValid = false;
        }
      }

      if (!newPresentation.value.presentation_time) {
        formErrors.value.presentation_time = "Presentation time is required";
        isValid = false;
      }

      return isValid;
    };

    // Validate and submit form
    const validateAndSubmit = () => {
      if (validateForm()) {
        addNewPresentation();
      }
    };

    // Add or update a presentation
    const addNewPresentation = async () => {
      isSubmitting.value = true;
      formErrors.value = {};

      try {
        // Ensure presenter ID is set
        newPresentation.value.ID = presentersID.value;

        if (isEditing.value) {
          await updateSchedule();
        } else {
          await createNewSchedule();
        }
      } catch (error) {
        console.error("Presentation submission error:", error);
        formErrors.value.general = "Failed to save presentation. Please try again.";
      } finally {
        isSubmitting.value = false;
      }
    };

    // Create a new presentation schedule
    const createNewSchedule = async () => {
      newPresentation.value['presentersID'] = presentersID.value;
      const response = await http.post("/PresentationSchedule", newPresentation.value);

      if (response.data && response.data.status === 200) {
        // Add to current schedule
        currentPresentationSchedule.value.push(response.data.record);

        // Reset form
        clearNewPresentationForm();
      } else {
        throw new Error("Failed to create presentation schedule");
      }
    };

    // Update an existing schedule entry
    const updateSchedule = async () => {
      if (!newPresentation.value.presentation_scheduleID) {
        throw new Error("Missing schedule ID for update");
      }

      // Ensure the ID field is set correctly
      newPresentation.value.ID = newPresentation.value.presentation_scheduleID;

      const response = await http.patch("/PresentationSchedule", newPresentation.value, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data && response.data.status === 200) {
        // Find and update the schedule in the local array
        const index = currentPresentationSchedule.value.findIndex(
          p => p.presentation_scheduleID === newPresentation.value.presentation_scheduleID
        );

        if (index !== -1) {
          currentPresentationSchedule.value[index] = response.data.record;
        }

        // Reset form
        clearNewPresentationForm();
      } else {
        throw new Error("Failed to update presentation schedule");
      }
    };

    // Edit an existing schedule entry
    const editSchedule = (row) => {
      isEditing.value = true;

      // Find the event in the list
      const event = myGroupsEvents.value.find(e => e.eventsID === row.event.eventsID);
      if (event) {
        workingEvent.value = event;
      }

      // Set form values
      newPresentation.value = {
        presentation_scheduleID: row.presentation_scheduleID,
        eventsID: row.event.eventsID,
        event_roomsID: row.room.event_roomsID,
        presentation_typesID: row.presentation_typesID ? row.presentation_typesID.toString() : "1",
        presentation_title: row.presentation_title,
        presentation_date: row.presentation_date,
        presentation_time: row.presentation_time,
        presentersID: row.presentersID
      };
    };

    // Navigate to sync upload page
    const goToSyncUploadPage = (row) => {
      if (!workingPresenter.value || !row.presentation_scheduleID) return;

      const url = encodeURI(`https://sync.meetingfiles.com/my-sync/auth.php?anyscreen=true&uuid=${workingPresenter.value.uuid}&pid=${row.presentation_scheduleID}`);
      window.open(url, "_blank");
    };

    // Clear the form
    const clearNewPresentationForm = () => {
      newPresentation.value = {...defaultPresentation};
      isEditing.value = false;
      workingEvent.value = null;
    };

    // Cancel editing
    const cancelEdit = () => {
      clearNewPresentationForm();
    };

    // Format date for display
    const formatDate = (dateString) => {
      if (!dateString) return "N/A";
      return dayjs(dateString).format("MMM D, YYYY");
    };

    // Format time for display
    const formatTime = (timeString) => {
      if (!timeString) return "N/A";
      return dayjs(`2000-01-01 ${timeString}`).format("h:mm A");
    };

    // Set the minimum and maximum dates based on the event
    const minDate = computed(() => workingEvent.value ? workingEvent.value.event_start_date : null);
    const maxDate = computed(() => workingEvent.value ? workingEvent.value.event_end_date : null);

    // Watch for changes in the route parameters
    watch(() => route.params.ID, newID => {
      if (newID) {
        presentersID.value = newID;
        setPresenter();
      }
    });

    // Initialize on mount
    onMounted(() => {
      if (presentersID.value) {
        setPresenter();
      } else {
        error.value = true;
        errorMessage.value = "No presenter ID provided";
        loading.value = false;
      }
    });

    return {
      // State
      workingPresenter,
      currentPresentationSchedule,
      newPresentation,
      workingEvent,
      myGroupsEvents,
      isEditing,
      loading,
      error,
      errorMessage,
      isSubmitting,
      formErrors,

      // Computed
      minDate,
      maxDate,

      // Methods
      selectEvent,
      validateAndSubmit,
      editSchedule,
      goToSyncUploadPage,
      cancelEdit,
      formatDate,
      formatTime
    };
  }
};
</script>

<style scoped>
.text-danger {
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
</style>
