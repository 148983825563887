<template>
  <div id="groupsAdminView">
    <h2>Groups Admin</h2>

    <!-- Loading State -->
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading groups...</span>
      </div>
    </div>

    <div v-else class="row">
      <!-- Add Group Form -->
      <div class="col-lg-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h4>Add a Group</h4>
          </div>
          <div class="card-body">
            <!-- Form Messages -->
            <div v-if="showMessage" class="alert" :class="isError ? 'alert-danger' : 'alert-success'" role="alert">
              {{ formMessage }}
            </div>

            <form @submit.prevent="validateAndSubmit">
              <div class="mb-3">
                <label for="groupName" class="form-label">Group Name</label>
                <input
                  type="text"
                  id="groupName"
                  class="form-control"
                  v-model="newGroupData.group_name"
                  :class="{'is-invalid': formErrors.group_name}"
                  :disabled="isSubmitting"
                  required
                />
                <div v-if="formErrors.group_name" class="invalid-feedback">
                  {{ formErrors.group_name }}
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 mb-3">
                  <label for="groupAddress" class="form-label">Group Address</label>
                  <input
                    type="text"
                    id="groupAddress"
                    class="form-control"
                    v-model="newGroupData.group_address"
                    :disabled="isSubmitting"
                  />
                </div>

                <div class="col-md-6 mb-3">
                  <label for="groupCity" class="form-label">City</label>
                  <input
                    type="text"
                    id="groupCity"
                    class="form-control"
                    v-model="newGroupData.group_city"
                    :disabled="isSubmitting"
                  />
                </div>

                <div class="col-md-3 mb-3">
                  <label for="groupState" class="form-label">State</label>
                  <input
                    type="text"
                    id="groupState"
                    class="form-control"
                    v-model="newGroupData.group_state"
                    :disabled="isSubmitting"
                  />
                </div>

                <div class="col-md-3 mb-3">
                  <label for="groupZip" class="form-label">Zip Code</label>
                  <input
                    type="text"
                    id="groupZip"
                    class="form-control"
                    v-model="newGroupData.group_zip"
                    :disabled="isSubmitting"
                  />
                </div>
              </div>

              <div class="mb-3">
                <label for="groupCountry" class="form-label">Country</label>
                <input
                  type="text"
                  id="groupCountry"
                  class="form-control"
                  v-model="newGroupData.group_country"
                  :disabled="isSubmitting"
                />
              </div>

              <div class="mb-3">
                <label for="groupPhone" class="form-label">Phone Number</label>
                <input
                  type="tel"
                  id="groupPhone"
                  class="form-control"
                  v-model="newGroupData.group_phone"
                  :disabled="isSubmitting"
                />
              </div>

              <div class="mb-3">
                <label for="groupWebsite" class="form-label">Website</label>
                <input
                  type="url"
                  id="groupWebsite"
                  class="form-control"
                  v-model="newGroupData.group_website"
                  placeholder="https://"
                  :disabled="isSubmitting"
                />
              </div>

              <div class="mb-3">
                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="isSubmitting"
                >
                  <span v-if="isSubmitting">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Creating...
                  </span>
                  <span v-else>Create Group</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Groups List -->
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h4>Existing Groups</h4>
            <button
              class="btn btn-sm btn-outline-primary"
              @click="refreshGroups"
              :disabled="isRefreshing"
            >
              <i class="bi bi-arrow-clockwise me-1"></i>
              <span v-if="isRefreshing">Refreshing...</span>
              <span v-else>Refresh</span>
            </button>
          </div>
          <div class="card-body">
            <div v-if="isRefreshing" class="text-center my-3">
              <div class="spinner-border spinner-border-sm text-primary" role="status">
                <span class="visually-hidden">Refreshing...</span>
              </div>
              <span class="ms-2">Refreshing groups...</span>
            </div>

            <div v-else-if="groupsArray && groupsArray.length > 0" class="table-responsive">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Location</th>
                  <th>Contact</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="group in groupsArray" :key="group.groupsID">
                  <td>{{ group.groupsID }}</td>
                  <td>{{ group.group_name }}</td>
                  <td>
                      <span
                        class="badge"
                        :class="group.group_status === 'active' ? 'bg-success' : 'bg-secondary'"
                      >
                        {{ group.group_status || 'N/A' }}
                      </span>
                  </td>
                  <td>
                    <div v-if="group.group_address || group.group_city || group.group_state">
                      {{ group.group_address }}<br v-if="group.group_address">
                      {{ group.group_city }} {{ group.group_state }} {{ group.group_zip }}
                    </div>
                    <span v-else class="text-muted">No address</span>
                  </td>
                  <td>
                    <div v-if="group.group_phone || group.group_website">
                      <div v-if="group.group_phone">
                        <i class="bi bi-telephone-fill me-1"></i> {{ group.group_phone }}
                      </div>
                      <div v-if="group.group_website">
                        <a :href="formatWebsiteUrl(group.group_website)" target="_blank" rel="noopener noreferrer">
                          <i class="bi bi-globe me-1"></i> Website
                        </a>
                      </div>
                    </div>
                    <span v-else class="text-muted">No contact info</span>
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-outline-secondary"
                      @click="editGroup(group)"
                      aria-label="Edit group"
                    >
                      <i class="bi bi-pencil-square me-1"></i> Edit
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <p class="alert alert-warning">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>
                No group data to show.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useGroupStore } from "@/store/modules/Groups";
import { http } from "@/api"; // Use the configured HTTP client

export default {
  name: "GroupsAdminView",
  setup() {
    // Store
    const groupStore = useGroupStore();

    // State
    const newGroupData = ref({
      group_name: "",
      group_address: "",
      group_city: "",
      group_state: "",
      group_zip: "",
      group_country: "",
      group_phone: "",
      group_website: ""
    });

    const loading = ref(true);
    const isSubmitting = ref(false);
    const isRefreshing = ref(false);
    const showMessage = ref(false);
    const isError = ref(false);
    const formMessage = ref('');
    const formErrors = ref({});

    // Computed properties
    const groupsArray = computed(() => groupStore.groupsArray || []);

    // Form validation
    const validateForm = () => {
      formErrors.value = {};
      let isValid = true;

      if (!newGroupData.value.group_name.trim()) {
        formErrors.value.group_name = "Group name is required";
        isValid = false;
      }

      if (newGroupData.value.group_website && !isValidUrl(newGroupData.value.group_website)) {
        formErrors.value.group_website = "Please enter a valid website URL";
        isValid = false;
      }

      return isValid;
    };

    const isValidUrl = (url) => {
      if (!url) return true; // Empty URLs are valid (optional field)
      try {
        // Add http:// if missing for URL validation
        const urlToTest = url.match(/^https?:\/\//) ? url : `http://${url}`;
        new URL(urlToTest);
        return true;
      } catch (e) {
        return false;
      }
    };

    // Submit handler
    const validateAndSubmit = () => {
      if (validateForm()) {
        submitNewGroup();
      }
    };

    // Create new group
    const submitNewGroup = async () => {
      isSubmitting.value = true;
      showMessage.value = false;

      try {
        const response = await http.post("/Group", newGroupData.value);

        if (response.data && response.data.status === 200) {
          // Add group to store
          groupStore.addGroupToArray(response.data.record);

          // Show success message
          formMessage.value = "Group created successfully";
          isError.value = false;
          showMessage.value = true;

          // Clear the form
          clearForm();

          // Hide message after 3 seconds
          setTimeout(() => {
            showMessage.value = false;
          }, 3000);
        } else {
          throw new Error(response.data?.message || "Failed to create group");
        }
      } catch (error) {
        console.error("Error creating group:", error);

        // Show error message
        formMessage.value = error.response?.data?.message || "Failed to create group. Please try again.";
        isError.value = true;
        showMessage.value = true;
      } finally {
        isSubmitting.value = false;
      }
    };

    // Clear form fields
    const clearForm = () => {
      Object.keys(newGroupData.value).forEach((key) => {
        if (typeof newGroupData.value[key] === "string") {
          newGroupData.value[key] = "";
        } else if (Array.isArray(newGroupData.value[key])) {
          newGroupData.value[key] = [];
        }
      });
      formErrors.value = {};
    };

    // Refresh groups data
    const refreshGroups = async () => {
      isRefreshing.value = true;

      try {
        await groupStore.setGroupsData();
      } catch (error) {
        console.error("Error refreshing groups:", error);
      } finally {
        isRefreshing.value = false;
      }
    };

    // Edit group (placeholder - to be implemented)
    const editGroup = (group) => {
      // This would be implemented in a future update
      console.log("Edit group:", group);
      alert("Edit functionality will be implemented in a future update.");
    };

    // Format website URL for display
    const formatWebsiteUrl = (url) => {
      if (!url) return '';
      return url.match(/^https?:\/\//) ? url : `http://${url}`;
    };

    // Initialize component
    onMounted(async () => {
      loading.value = true;

      try {
        if (!groupStore.groupsArray || groupStore.groupsArray.length === 0) {
          await groupStore.setGroupsData();
        }
      } catch (error) {
        console.error("Error loading groups:", error);
      } finally {
        loading.value = false;
      }
    });

    return {
      // State
      newGroupData,
      loading,
      isSubmitting,
      isRefreshing,
      showMessage,
      isError,
      formMessage,
      formErrors,

      // Computed
      groupsArray,

      // Methods
      validateAndSubmit,
      clearForm,
      refreshGroups,
      editGroup,
      formatWebsiteUrl
    };
  }
};
</script>

<style scoped>
.invalid-feedback {
  display: block;
}

.table-responsive {
  overflow-x: auto;
}

.card-header {
  background-color: #f8f9fa;
}

@media (min-height: 700px) {
  .card-body {
    max-height: 70vh;
    overflow-y: auto;
  }
}

td {
  vertical-align: middle;
}
</style>
