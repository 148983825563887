<template>
  <header class="navbar navbar-expand-md navbar-dark sticky-top bg-dark p-0 shadow">
    <div class="container-fluid">
      <a class="navbar-brand d-flex text-center" href="#">
        <img src="https://meetingfiles.com/assets/images/sync-logo-white.png" class="" width="100"/>
      </a>
      <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="w-50 ms-auto">
        <PresenterSearchForm />
      </div>

      <ul class="nav navbar-nav ms-auto justify-content-end">
        <li class="nav-item" v-if="syncStation && syncStation.event_roomsID">
          <router-link :to="{ name: 'EventRoomSchedule', params: { ID: syncStation.event_roomsID }}" class="nav-link px-3 text-primary">
            {{ syncStation.station_name }} - {{ syncStation.room_name }}
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link px-3" href="#" @click.prevent="exitApp()">Sign out</a>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { computed } from "vue";
import { useSyncMachineStore } from "@/store/modules/SyncMachine";
import { useUserStore } from "@/store/modules/UseUserStore"; // Add this import
import { useRouter } from "vue-router";
import PresenterSearchForm from "@/components/Forms/PresenterSearchForm.vue";

export default {
  name: "HeaderComponent",
  components: {
    PresenterSearchForm
  },
  setup() {
    const syncMachineStore = useSyncMachineStore();
    const userStore = useUserStore();
    const router = useRouter();

    const syncStation = computed(() => syncMachineStore.getSyncMachine);

    const exitApp = async () => {
      try {
        await userStore.logOut();
        await router.push("/");
      } catch (error) {
        console.log(error);
      }
    };

    const goToRoom = () => {
      if (syncStation.value) {
        router.push(`/app/events/schedule/view/${syncStation.value.event_roomsID}`);
      }
    };

    return {
      syncStation,
      exitApp,
      goToRoom
    };
  }
};
</script>

<style scoped>

</style>
